<template>
    <v-select v-model="selectedValue" 
                :items="years" 
                label="Select Year"
                filled
                :disabled="disabled"
    > 
    </v-select>
</template>

<script>
export default {
    name: "YearSelector",
    data: function () {
        return {
        };
    },
    props: {
        selectedYear: {
            type: Number,
            required: true
        },
        startYear: {
            type: Number,
            default: 2022
        },
        currentYear: {
            type: Number,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        selectedValue: {
            get() {
                return this.selectedYear;
            },
            set(newValue) {
                this.$gtag.event('change year', { 
                    'event_category': 'Interaction',
                    'event_label': 'Year Change',
                    'value': newValue
                });
                this.$emit('input', newValue);
                this.$emit('change', newValue);
            }
        },
        years() {
            var values = [];
            // Admins get access to all years
            // Non-admins, only the current year
            if (this.isAdmin) {
                for (var i = this.startYear; i <= this.currentYear; i++)
                    values.push(i);
            } else {
                values.push(this.currentYear);
            }
            return values;
        },
        isAdmin() {
            return this.isAuthenticated && this.$auth0.user['https://dfs.sportsbytez.com/roles'] && this.$auth0.user['https://dfs.sportsbytez.com/roles'].includes('admin');
        },
        isAuthenticated() {
            return this.$auth0.isAuthenticated;
        }
    }
};
</script>
<style scoped>
</style>