<template>
    <v-container>
      <v-row>
        <v-alert class="m-auto" type="error" v-if="isError">
          Oops, there as an error while trying to login!
        </v-alert>
      </v-row>
    </v-container>
</template>
  
<script>
    export default {
        name: "Callback",
        data: function () {
            return {
              isError: false
            };
        },
        mounted: function() {
          const urlParams = new URLSearchParams(window.location.search);
          const errorParam = urlParams.get('error');
          this.isError = errorParam;
        }
    };
</script>

<style scoped>
.m-auto {
  margin: auto;
  margin-top: 20px;
}
</style>