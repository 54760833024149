<template>
    <v-container style="padding: 20px; width: 60%;">
        <v-row style="margin-top: 2em;">
            <h3 style="margin: auto;">Thank you for using the SportsBytez DFS Optimizer!</h3>
            <p style="width: 72%; padding: 20px; margin: auto;">
                Use the navigation menu above to go to the optimizer you would like to use. And please remember to reach out to us with any issues you encounter or any feedback you have on how you think we could make our optimizer even better!
            </p>
        </v-row>      
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'PaymentSuccess',
    data() {
      return {
      };
    }
  };
  </script>
  
  <style scoped>
  </style>
  