<template>
    <MyContests :provider="provider"
                :contests="contests"
                :uploading="uploading"
                :upload-errors="uploadErrors"
                :view-contest-id="viewingContest?.contestId"
                @upload="upload"
                @clear-file="clearFile"
                @file-changed="fileChanged"
                @delete-contest="removeContest"
                @view-contest="viewContest">
        <template v-slot:lineup-view>
            <v-row v-if="viewingContest">
                <v-col cols="6" v-for="(lineup, idx) in contestLineups" :key="idx"> 
                    <LineupView :lineup="lineup" :allow-save-remove-lineup="false" />
                </v-col>
            </v-row>
        </template>
    </MyContests>
</template>
  
<script>
    import MyContests from '@/components/shared/MyContests.vue';
    import LineupView from '@/components/football/LineupView.vue';
    import RestClient from "@/services/FootballRestClient";

    import { createNamespacedHelpers } from 'vuex';
    const { mapState, mapActions } = createNamespacedHelpers('football');

    export default {
        name: "FootballContests",
        components: {
            MyContests,
            LineupView
        },
        data: function () {
            return {
                uploading: false,
                uploadErrors: [],
                viewingContest: null,
            };
        },
        mounted: function() {
            this.$store.subscribe((mutation) => {
                // When slates change, reset our viewing contest
                if (mutation.type === 'football/UPDATE_SLATE' || mutation.type === 'football/UPDATE_YEAR' || mutation.type === 'football/UPDATE_WEEK' ||mutation.type === 'football/UPDATE_PROVIDER') {
                    this.viewingContest = null;
                }
            });
        },
        computed: {
            contestLineups() {
                if (!this.viewingContest)
                    return [];
                return this.viewingContest.entries.map(s => {
                    // Unfortunately this matters by both contest type and provider. Need to find a better way to do this
                    if (this.slate.numberOfGames > 1) {
                        return {
                            captainSpots: [],
                            defenses: [this.getProjection(s.position9)],
                            flexSpots: [this.getProjection(s.position8)],
                            mvpSpots: [],
                            quarterbacks: [this.getProjection(s.position1)],
                            runningbacks: [
                                this.getProjection(s.position2),
                                this.getProjection(s.position3),
                            ],
                            tightEnds: [this.getProjection(s.position7)],
                            wideReceivers: [
                                this.getProjection(s.position4),
                                this.getProjection(s.position5),
                                this.getProjection(s.position6),
                            ],
                        };
                    } else {
                        if (this.provider.abbreviation === 'DK') {
                            return {
                                captainSpots: [this.getProjection(s.position1)],
                                defenses: [],
                                flexSpots: [
                                    this.getProjection(s.position2),
                                    this.getProjection(s.position3),
                                    this.getProjection(s.position4),
                                    this.getProjection(s.position5),
                                    this.getProjection(s.position6),
                                ],
                                mvpSpots: [],
                                quarterbacks: [],
                                runningbacks: [],
                                tightEnds: [],
                                wideReceivers: [],
                            };
                        } else if (this.provider.abbreviation === 'FD') {
                            return {
                                captainSpots: [],
                                defenses: [],
                                flexSpots: [
                                    this.getProjection(s.position2),
                                    this.getProjection(s.position3),
                                    this.getProjection(s.position4),
                                    this.getProjection(s.position5),
                                ],
                                mvpSpots: [this.getProjection(s.position1)],
                                quarterbacks: [],
                                runningbacks: [],
                                tightEnds: [],
                                wideReceivers: [],
                            };
                        }
                    }
                    return {
                        captainSpots: [],
                        defenses: [],
                        flexSpots: [],
                        mvpSpots: [],
                        quarterbacks: [],
                        runningbacks: [],
                        tightEnds: [],
                        wideReceivers: [],
                    };
                });
            },
            ...mapState({
                provider: state => state.providers.selected,
                slate: state => state.slates.selected,
                contests: state => state.contests.data,
                projections: state => state.projections.data,
            })
        },
        methods: {
            clearFile: function() {
                // clear errors on clear
                this.uploadErrors = [];
            },
            fileChanged: function() {
                // clear errors on change
                this.uploadErrors = [];
            },
            removeContest: function(contestId) {
                // if the viewing contest is being deleted, stop viewing it
                if (this.viewingContest?.contestId === contestId)
                    this.viewingContest = null;

                RestClient.contestEntries()
                    .removeContest(this.provider.id, this.slate.id, contestId)
                    .then(() => {
                        this.$root.$emit('snackbar', {
                            text: 'Contest was successfully removed!',
                            color: 'success'
                        });
                        this.loadContests();
                    })
                    .catch(() => this.errorMessage = 'An error occurred while removing the contest.');
                this.$gtag.event('Removed Contest');
            },
            upload: function(uploadFile, successCallback, errorCallback) {
                this.uploading = true;
                this.uploadErrors = [];

                if (!uploadFile) {
                    this.uploadErrors.push('You must select a file to upload!');
                    return;
                }

                let formData = new FormData();
                formData.append("file", uploadFile, uploadFile.name);

                RestClient.contestEntries()
                    .uploadEntries(this.provider.id, this.slate.id, formData)
                    .then((response) => {
                        if (response.status !== 200) {
                            this.uploadErrors.push('An error occurred while uploading the file. Ensure the file is in the correct format and for this game slate.');
                        } else {
                            this.uploading = false;
                            this.$root.$emit('snackbar', {
                                text: 'Contest entry file was successfully uploaded!',
                                color: 'success'
                            });
                            this.loadContests();
                            successCallback?.();
                        }
                    })
                    .catch(() => {
                        this.uploading = false;
                        this.uploadErrors.push('An error occurred while uploading the file. Ensure the file is in the correct format.');    
                        errorCallback?.();
                    });
                this.$gtag.event('Upload Contest File');
            },
            viewContest(contestId) {
                this.viewingContest = this.contests.find(c => c.contestId === contestId);
                this.$gtag.event('View Contest Entries');
            },
            getProjection(projectionId) {
                return this.projections.find(p => p.id === projectionId || p.salary.providerPlayerId === projectionId);
            },
            ...mapActions([
                'loadContests'
            ])
        }
    };
</script>
<style scoped>
    div.myc-container {
        margin: 20px 10px;
    }
    div.upload-row::v-deep div.v-text-field__details {
        display: flex;
    }
    a {
        color:aliceblue;
    }
</style>