<template>
    <v-container>
      <v-row>
        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            Name
                        </th>
                        <th class="text-left">
                            Games Played
                        </th>
                        <th class="text-left">
                            Passing Yards
                        </th>
                        <th class="text-left">
                            Passing Yards Per Game
                        </th>
                        <th class="text-left">
                            Rushing Yards
                        </th>
                        <th class="text-left">
                            Rushing Yards Per Game
                        </th>
                        <th class="text-left">
                            Total Yards
                        </th>
                        <th class="text-left">
                            Yards Per Game
                        </th>
                        <th class="text-left">
                            Total Points
                        </th>
                        <th class="text-left">
                            Points Per Game
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in teamStats" :key="item.team.id">
                        <td>{{ item.team.fullName }}</td>
                        <td>{{ item.gamesPlayed }}</td>
                        <td>{{ item.passingStats.passYards }}</td>
                        <td>{{ item.passingYardsPerGame.toFixed(1) }}</td> 
                        <td>{{ item.rushingStats.yards }}</td>
                        <td>{{ item.rushingYardsPerGame.toFixed(1) }}</td>
                        <td>{{ item.totalYards }}</td>
                        <td>{{ item.yardsPerGame.toFixed(1) }}</td>
                        <td>{{ item.totalPointsScored }}</td>
                        <td>{{ item.pointsScoredPerGame.toFixed(1) }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
      </v-row>
    </v-container>
</template>
  
<script>
    import RestClient from "@/services/FootballRestClient";

    export default {
        name: "Stats",
        data: function () {
            return {
                teamStats: [],
                playerStats: []
            };
        },
        mounted: function() {
            RestClient.stats()
                .getTeam(2022)
                .then((response) => {
                    this.teamStats = response.data;
                });
            RestClient.stats()
                .getPlayer(2022)
                .then((response) => {
                    this.playerStats = response.data;
                });
        }
    };
</script>
<style scoped>
</style>