import * as headers from './ProjectionHeaders';

export default function(position) {
    if (position === 'DEF') {
        return [
            headers.IconLockColumn,
            headers.PlayerNameColumn,
            headers.SalaryPositionColumn,
            headers.StatusColumn,
            headers.SalaryColumn,
            headers.TeamColumn,
            headers.OpponentColumn,
            headers.ProjectedPointsColumn,
            headers.CustomProjectedPointsColumn,
            headers.ValueColumn,
            //headers.ProjectedOwnColumn,
            headers.MinExposureColumn,
            headers.MaxExposureColumn
        ];
    }
    return [
        headers.IconLockColumn,
        headers.PlayerNameColumn,
        headers.SalaryPositionColumn,
        headers.StatusColumn,
        headers.SalaryColumn,
        headers.TeamColumn,
        headers.OpponentColumn,
        headers.PassYds,
        headers.PassTds,
        headers.RushYds,
        headers.RushTds,
        headers.RecYds,
        headers.RecTds,
        headers.Targets,
        headers.ADot,
        headers.WOPR,
        headers.ImpliedTouches,
        headers.ProjectedPointsColumn,
        headers.CustomProjectedPointsColumn,
        headers.ValueColumn,
        //headers.ProjectedOwnColumn,
        headers.MinExposureColumn,
        headers.MaxExposureColumn
    ];
}