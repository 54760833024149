import Vue from 'vue';
import Vuex from 'vuex'
import football from './modules/football';
import baseball from './modules/baseball';
import user from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    football,
    baseball
  }
})