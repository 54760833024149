<template>
    <v-text-field
        class="centered-input"
        v-model="editableValue"
        solo
        :disabled="disabled"
        @blur="updateValue"
        @keydown.enter="submit"
    ></v-text-field>
</template>
  
<script>
  export default {
    name: "EditableValue",
    props: {
      value: {
        type: Number,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
    }),
    computed: {
        editableValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                // Update v-model only
                this.$emit('input', Number(newValue.toString()));
            }
        }
    },
    methods: {
        updateValue() {
            // emit updated event to save values
            this.$emit('updated');
        },
        submit(event) {
            event.preventDefault();
            this.updateValue();
        }
    }
  };
</script>
<style scoped>
    .v-input--is-disabled >>> .v-input__slot {
        background: darkslategray !important;
    }
</style>