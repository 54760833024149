<template>
    <v-container style="padding: 20px; width: 60%;">
        <h3>Terms and Conditions</h3>
        <h3>Effective Date: 8/13/2024</h3>

        <br />
        <h4>1. Introduction</h4>
        Welcome to SportsBytez DFS Optimizer (the “Website”). These Terms and Conditions (“Terms”) govern your use of our Website and services. By accessing or using the Website, you agree to be bound by these Terms.

        <p />
        <h4>2. Acceptance of Terms</h4>
        By accessing or using the Website, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, you must not use the Website.

        <p />
        <h4>3. How We Use Your Information</h4>
        You agree not to engage in any of the following prohibited activities:
        <ul>
            <li>Using the Website for any illegal purpose or to violate any laws.</li>
            <li>Attempting to interfere with, compromise the system integrity or security, or decipher any transmissions to or from the servers running the Website.</li>
            <li>Taking any action that imposes, or may impose, at our sole discretion, an unreasonable or disproportionately large load on our infrastructure.</li>
            <li>Uploading invalid data, viruses, worms, or other software agents through the Website.</li>
            <li>Collecting or harvesting any personally identifiable information from the Website.</li>
            <li>Using the Website in any manner that could interfere with, disrupt, negatively affect, or inhibit other users from fully enjoying the Website.</li>
        </ul>

        <p />
        <h4>4. Intellectual Property</h4>
        The content, features, and functionality on the Website, including but not limited to text, graphics, logos, and software, are owned by SportsBytez or its licensors and are protected by copyright, trademark, and other intellectual property laws. You may not use, copy, reproduce, distribute, or create derivative works from any part of the Website without our prior written consent.

        <p />
        <h4>5. Disclaimer of Warranties</h4>
        The Website and all content, products, and services provided through the Website are provided on an “as is” and “as available” basis, without any warranties of any kind, either express or implied. We do not warrant that the Website will be uninterrupted or error-free, that defects will be corrected, or that the Website is free of viruses or other harmful components.

        <p />
        <h4>6. Limitation of Liability</h4>
        To the fullest extent permitted by applicable law, SportsBytez shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
        <ul>
            <li>Your use of or inability to use the Website.</li>
            <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
            <li>Any interruption or cessation of transmission to or from the Website.</li>
        </ul>

        <p />
        <h4>7. Indemnification</h4>
        You agree to indemnify, defend, and hold harmless SportsBytez, its officers, directors, employees, and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt, and expenses (including but not limited to attorney’s fees) arising from your use of the Website or your violation of these Terms.

        <p />
        <h4>8. Governing Law</h4>
        These Terms shall be governed by and construed in accordance with the laws of Wisconsin (USA), without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the courts located in Wisconsin (USA) to resolve any dispute arising out of these Terms or the Website.

        <p />
        <h4>9. Changes to Terms</h4>
        We reserve the right to modify these Terms at any time. If we make changes to these Terms, we will provide notice of such changes by posting the updated Terms on the Website and updating the “Effective Date” at the top of these Terms. Your continued use of the Website following the posting of changes constitutes your acceptance of such changes.

        <p />
        <h4>10. Contact Us</h4>
        If you have any questions about these Terms, please contact us at <a href="mailto:support@sportsbytez.com">support@sportsbytez.com</a>.
    </v-container>
</template>

<script>
    export default {
        name: "TermsConditions",
        data: function () {
            return {
            };
        },
        methods: {
        }
    };
</script>

<style scoped>
.m-auto {
  margin: auto;
  margin-top: 20px;
}
</style>