<template>
    <v-select :value="selectedWeek" 
                :items="weeks"
                label="Select Week"
                item-text="display"
                item-value="week"
                @change="selected"
                filled
                single-line
                :disabled="disabled"
    > 
    </v-select>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('football');

export default {
    name: "WeekSelector",
    data: function () {
        return {
        };
    },
    props: {
        value: {
            type: Number
        },
        backtesting: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        year: {
            type: Number,
            default: null
        }
    },
    computed: {
        weeks() {
            var values = [];
            // If the current year is selected, only allow selection up to the current week
            var maxWeek = this.useYear === this.currentYear ? this.currentWeek : 22;
            for (var i = 1; i <= maxWeek; i++) {
                // Do not allow the current week if we are backtesting
                if (!this.backtesting || i !== this.currentWeek) {
                    var val = { week: i, display: `Week ${i.toString()}` };
                    if (i === 19)
                        val.display = 'Wild Card Round';
                    if (i === 20)
                        val.display = 'Divisional Round';
                    if (i === 21)
                        val.display = 'Conference Championships';
                    if (i === 22)
                        val.display = 'Super Bowl';
                    values.push(val);
                }
            }
            return values;
        },
        useYear() {
            if (this.year)
                return this.year;
            return this.selectedYear;
        },
        ...mapState({
            currentYear: state => state.currentYear,
            selectedYear: state => state.selectedYear,
            currentWeek: state => state.currentWeek,
            selectedWeek: state => state.selectedWeek
        })
    },
    methods: {
        selected(value) {
            this.$emit('input', value);
            this.$emit('change');
            this.changeWeek(value);
        },
        ...mapActions([
            'changeWeek'
        ])
    }
};
</script>
<style scoped>
</style>