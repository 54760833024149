<template>
    <div>
        <v-menu offset-y class="menu-content">
            <template v-slot:activator="{ on, attrs }">
                <img v-bind="attrs"
                    v-on="on"
                    class="profile_pic"
                    :src="picture" />
            </template>
            <v-list>
                <v-list-item>
                    <RouterLink to="/account">Account Details</RouterLink>
                </v-list-item>
                <v-list-item>
                    <a @click.prevent="handleLogout">Log Out</a>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
    
</template>
<script>
    export default {
        name: 'UserMenu',
        data: function() {
            return {
                showMenu: false,
                x: 0,
                y: 0,
            }
        },
        computed: {
            picture() {
                return this.$auth0.user['picture'];
            },
            nickname() {
                return this.$auth0.user['nickname'];
            },
            email() {
                return this.$auth0.user['email'];
            },
            activator() {
                if (this.picture)
                    return this.$refs.picBtn;
                return this.$refs.linkBtn;
            }
        },
        methods: {
            handleLogout() {
                this.$auth0.logout({ logoutParams: { returnTo: process.env.VUE_APP_AUTH0_LOGOUT_URL } });
            },  
        }
    }
</script>
<style scoped>
img.profile_pic {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-top: 8px;
}
a {
    text-decoration: none;
}
</style>