<template>
    <BuildSettings :savedSettings="savedSettings"
        @set-saved-settings="setSavedSettings"
        @delete-saved-settings="deleteSavedSettings"
        @load-to-current-settings="loadToCurrentSettings">
        <v-row>
            <v-alert
                v-if="validateBuildSettings.includes('numberOfLineupsToBuildTooMany')"
                outlined
                type="error"
                prominent>
                Our optimizer allows up to {{ lineupBuildLimit }} lineups in a build. Reduce the number of lineups to build.
            </v-alert>
            <v-alert
                v-else-if="isOverMaximumLineups"
                outlined
                type="error"
                prominent>
                Your current tier allows for up to {{ maximumAllowedBuilds }} lineups. If you need to build more lineups check out our <RouterLink to="/plans">upgraded tiers here!</RouterLink>
            </v-alert>
            <v-col cols="12">
                <v-text-field
                    label="Number of Lineups To Build"
                    v-model="localSettings.numberOfLineupsToBuild"
                    @keydown="checkDigit"
                    @change="updatedLocalSettings"
                ></v-text-field>
            </v-col>
            <span class="val-error" v-if="validateBuildSettings.includes('numberOfLineupsToBuildRequired')">Number of Lineups to Build is required!</span>
            <span class="val-error" v-if="validateBuildSettings.includes('numberOfLineupsToBuildMinimum')">Number of Lineups must be greater than 0!</span>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-text-field
                    label="Minimum Projected Points"
                    v-model="localSettings.minimumProjectedPointsToInclude"
                    @keydown="checkDecimal"
                    @change="updatedLocalSettings"
                ></v-text-field>
            </v-col>
            <span class="val-error" v-if="validateBuildSettings.includes('minimumProjectedPointsToIncludeRequired')">Minimum Projected Points is required!</span>
            <span class="val-error" v-if="validateBuildSettings.includes('minimumProjectedPointsToIncludeMinimum')">Minimum Projected Points must be 0 or greater!</span>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-text-field
                    label="Minimum Unique Players in Lineups"
                    v-model="localSettings.minimumUniquePlayers"
                    @keydown="checkDigit"
                    @change="updatedLocalSettings"
                ></v-text-field>
            </v-col>
            <span class="val-error" v-if="validateBuildSettings.includes('minimumUniquePlayersRequired')">Minimum Unique Players in Lineups is required!</span>
            <span class="val-error" v-if="validateBuildSettings.includes('minimumUniquePlayersMinimum')">Minimum Unique Players in Lineups must be greater than 0!</span>
        </v-row>
        <v-row v-if="isMultiGameSlate">
            <v-col cols="12">
                <v-text-field
                    label="Maximum Number of Players From Same Team"
                    v-model="localSettings.maximumPlayersFromSameTeam"
                    @keydown="checkDigit"
                    @change="updatedLocalSettings"
                ></v-text-field>
            </v-col>
            <span class="val-error" v-if="validateBuildSettings.includes('maximumPlayersFromSameTeamRequired')">Maximum Number of Players From Same Team is required!</span>
            <span class="val-error" v-if="validateBuildSettings.includes('maximumPlayersFromSameTeamMinimum')">Maximum Number of Players From Same Team must be 0 or greater!</span>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-text-field
                    label="Minimum Salary to Use"
                    v-model="localSettings.minimumSalary"
                    @keydown="checkDigit"
                    @change="updatedLocalSettings"
                ></v-text-field>
            </v-col>
            <span class="val-error" v-if="validateBuildSettings.includes('minimumSalaryRequired')">Minimum Salary to Use is required!</span>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-text-field
                    label="Maximum Salary to Use"
                    v-model="localSettings.maximumSalary"
                    @keydown="checkDigit"
                    @change="updatedLocalSettings"
                ></v-text-field>
            </v-col>
            <span class="val-error" v-if="validateBuildSettings.includes('maximumSalaryRequired')">Maximum Salary to Use is required!</span>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-text-field
                    label="Maximum Player Exposure"
                    v-model="localSettings.maxExposure"
                    @keydown="checkDigit"
                    @change="updatedLocalSettings"
                ></v-text-field>
            </v-col>
            <span class="val-error" v-if="validateBuildSettings.includes('maxExposureRequired')">Maximum Player Exposure is required!</span>
            <span class="val-error" v-if="validateBuildSettings.includes('maxExposureMinimum')">Maximum Player Exposure must be greater than 0!</span>
        </v-row>
        <v-row v-if="isMultiGameSlate">
            <v-col cols="12">
                <v-combobox
                    v-model="localSettings.includePositionsInUtil"
                    :items="flexPositionOptions"
                    label="Include Positions in UTIL"
                    multiple
                    chips
                    @change="updatedLocalSettings"
                ></v-combobox>
            </v-col>
        </v-row>
    </BuildSettings>
</template>

<script>
import BuildSettings from '@/components/shared/Settings/BuildSettings.vue';
import RestClient from "@/services/BaseballRestClient";

import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapState } = createNamespacedHelpers('baseball');

export default {
    name: "BaseballBuildSettings",
    components: {
        BuildSettings
    },
    data: function () {
        return {
            localSettings: {},
            flexPositionOptions: ['C','1B','2B','3B','SS','OF'],
            lineupBuildLimit: 500
        };
    },
    mounted: function () {
        // On initial mount, get current local settings
        this.updateLocalSettings();

        // When settings are updated in the store, apply them to the local settings
        this.$store.subscribe((mutation) => {
            if (mutation.type === 'baseball/UPDATE_SETTINGS') {
                this.updateLocalSettings();
            }
        });
    },
    computed: {
        isMultiGameSlate() {
            return this.slate?.numberOfGames > 1;
        },
        isOverMaximumLineups() {
            return this.localSettings.numberOfLineupsToBuild > this.maximumAllowedBuilds;
        },
        maximumAllowedBuilds() {
            return this.$store.getters.maxBuildsAllowed;
        },
        validateBuildSettings() {
            var validations = [];
            if (this.localSettings.numberOfLineupsToBuild > this.lineupBuildLimit)
                validations.push('numberOfLineupsToBuildTooMany');
            if (this.isRequired(this.localSettings.numberOfLineupsToBuild))
                validations.push('numberOfLineupsToBuildRequired');
            if (this.localSettings.numberOfLineupsToBuild && this.localSettings.numberOfLineupsToBuild <= 0)
                validations.push('numberOfLineupsToBuildMinimum');
            if (this.isRequired(this.localSettings.minimumProjectedPointsToInclude))
                validations.push('minimumProjectedPointsToIncludeRequired');
            if (this.localSettings.minimumProjectedPointsToInclude && this.localSettings.minimumProjectedPointsToInclude < 0)
                validations.push('minimumProjectedPointsToIncludeMinimum');
            if (this.isRequired(this.localSettings.minimumUniquePlayers))
                validations.push('minimumUniquePlayersRequired');
            if (this.localSettings.minimumUniquePlayers && this.localSettings.minimumUniquePlayers <= 0)
                validations.push('minimumUniquePlayersMinimum');
            if (this.isRequired(this.localSettings.minimumSalary))
                validations.push('minimumSalaryRequired');
            if (this.isRequired(this.localSettings.maximumSalary))
                validations.push('maximumSalaryRequired');
            if (this.isRequired(this.localSettings.maxExposure))
                validations.push('maxExposureRequired');
            if (this.localSettings.maxExposure && this.localSettings.maxExposure <= 0)
                validations.push('maxExposureMinimum');
            // The following validations are only required in multi game slates
            if (this.isMultiGameSlate) {
                if (this.isRequired(this.localSettings.maximumPlayersFromSameTeam))
                    validations.push('maximumPlayersFromSameTeamRequired');
                if (this.localSettings.maximumPlayersFromSameTeam && this.localSettings.maximumPlayersFromSameTeam < 0)
                    validations.push('maximumPlayersFromSameTeamMinimum');
            }
            return validations;
        },
        ...mapState({
            slate: state => state.slates.selected,
            provider: state => state.providers.selected,
            settings: state => state.settings,
            savedSettings: state => state.savedSettings,
        })
    },
    methods: {
        isRequired(value) {
            if ((value !== 0 && !value) || value === '')
                return true;
            return false;
        },
        checkDigit(e) {
            // Backspace, delete, tab, (ctrl + V for paste), (ctrl + C for copy) and numbers
            if (!(e.keyCode == 86 && e.ctrlKey) && 
                !(e.keyCode == 67 && e.ctrlKey) && 
                e.keyCode !== 8 && 
                e.keyCode !== 46 && 
                e.keyCode !== 9 && 
                isNaN(Number(e.key))) {
                e.preventDefault();
            }
        },
        checkDecimal(e) {
            // Backspace, delete, tab, (ctrl + V for paste), (ctrl + C for copy) and numbers
            // Adds . for decimal values
            if (!(e.keyCode == 86 && e.ctrlKey) && 
                !(e.keyCode == 67 && e.ctrlKey) && 
                e.keyCode !== 8 && 
                e.keyCode !== 46 && 
                e.keyCode !== 9 && 
                e.keyCode !== 190 && 
                isNaN(Number(e.key))) {
                e.preventDefault();
            }
        },
        updateLocalSettings() {
            this.localSettings = {
                numberOfLineupsToBuild: this.settings.numberOfLineupsToBuild,
                minimumProjectedPointsToInclude: this.settings.minimumProjectedPointsToInclude,
                minimumUniquePlayers: this.settings.minimumUniquePlayers,
                minimumSalary: this.settings.minimumSalary,
                maximumSalary: this.settings.maximumSalary,
                includePositionsInUtil: this.settings.includePositionsInUtil,
                maximumPlayersFromSameTeam: this.settings.maximumPlayersFromSameTeam,
                maxExposure: this.settings.maxExposure
            };
        },
        updatedLocalSettings: function() {
            this.updateBuildSettingsErrors(this.validateBuildSettings);
            // Don't try to save build settings if we know there will be errors
            if (this.validateBuildSettings?.length === 0) { 
                this.saveBuildSettings({
                    settings: this.localSettings
                });
                this.$gtag.event('Updated Build Settings');
            }
        },
        deleteSavedSettings: function(settingsName) {
            RestClient.lineupOptimizer()
                .deleteSavedSettings(this.provider.id, this.slate.id, settingsName)
                .then(() => this.loadSavedBuildSettings());
            this.$gtag.event('Deleted Saved Settings');
        },
        loadToCurrentSettings: function(settings) {
            // save the build settings so they get applied as the current
            this.saveBuildSettings({
                settings: settings,
                callback: () => {
                    this.$root.$emit('snackbar', {
                        text: `Stored settings '${settings.settingsName}' have been loaded as the current build settings!`,
                        color: 'success'
                    });
                }
            });
            this.$gtag.event('Loaded Saved Settings');
        },
        setSavedSettings(payload) {
            this.setSavedBuildSettings(payload);
            this.$gtag.event('Set Saved Settings');
        },
        ...mapActions({
            loadSavedBuildSettings: 'loadSavedBuildSettings',
            saveBuildSettings: 'saveBuildSettings',
            setSavedBuildSettings: 'setSavedBuildSettings',
            updateBuildSettingsErrors: 'updateBuildSettingsErrors',
        })
    }
};
</script>
<style scoped>
.settings-container {
  margin-top: 20px;
  margin-left: 20px;
}
div.v-list-item {
    clear: both;
}
div.itm-left {
    width: 40%;
    float: left;
}
div.itm-right {
    width: 55%;
    float: right;
    height: 30px;
    text-align: right;
}
div.itm-right button {
    margin-left: 10px;
}
span.val-error {
    color: red;
    font-weight: bold;
    font-size: 12px;
    font-size: 12px;
    margin-left: 13px;
    margin-top: -10px;
}
</style>