<template>
    <div id="pricing-table" class="pricing-table" v-if="!hasPaidTier">
        <v-alert
            dense
            type="error"
            width="50%"
            style="margin: auto; margin-bottom: 50px;"
            v-if="loadingLinkError"
        >
            Unable to load your subscription page. We apologize for the inconvenience and are working to fix this right away!
        </v-alert>
        <v-row>
            <v-col cols="4"></v-col>
            <v-col cols="2">
                <v-card elevation="2" height="640">
                    <h5>For the Casual DFS Player</h5>
                    <h3>SportsBytez DFS Rookie Tier</h3>
                    <div class="desc">
                        Access to the SportsBytez DFS Lineup Optimizers with the ability to import contests from DraftKings and FanDuel and export your lineups directly.
                    </div>
                    <p class="price">
                        <strong>$15</strong> / month
                    </p>
                    <v-btn color="primary" class="subscribe-btn" @click="subscribe('price_1Pnk70IqoDcb4FVwXUZkR6Ra')">
                        Subscribe
                    </v-btn>
                    <p class="benefits">
                        This tier includes:
                    </p>
                    <table class="feature-table">
                        <tr>
                            <td class="icon-cell">
                                <v-icon
                                    small
                                    color="green darken-2"
                                >
                                    mdi-check-circle
                                </v-icon>
                            </td>
                            <td class="desc-cell">
                                <span>Generate up to 100 lineups through the optimizer</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="icon-cell">
                                <v-icon
                                    small
                                    color="green darken-2"
                                >
                                    mdi-check-circle
                                </v-icon>
                            </td>
                            <td class="desc-cell">
                                <span>Edit projections and exposures</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="icon-cell">
                                <v-icon
                                    small
                                    color="green darken-2"
                                >
                                    mdi-check-circle
                                </v-icon>
                            </td>
                            <td class="desc-cell">
                                <span>Access to rules builder to create specific build rules</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="icon-cell">
                                <v-icon
                                    small
                                    color="green darken-2"
                                >
                                    mdi-check-circle
                                </v-icon>
                            </td>
                            <td class="desc-cell">
                                <span>Access to stacks builder to create lineups with specific stacks</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="icon-cell">
                                <v-icon
                                    small
                                    color="green darken-2"
                                >
                                    mdi-check-circle
                                </v-icon>
                            </td>
                            <td class="desc-cell">
                                <span>Import contests from DraftKings and FanDuel with support for late swapping</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="icon-cell">
                                <v-icon
                                    small
                                    color="green darken-2"
                                >
                                    mdi-check-circle
                                </v-icon>
                            </td>
                            <td class="desc-cell">
                                <span>Export lineups to file that can be directly upload to DraftKings/FanDuel</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="icon-cell">
                                <v-icon
                                    small
                                    color="green darken-2"
                                >
                                    mdi-check-circle
                                </v-icon>
                            </td>
                            <td class="desc-cell">
                                <span>Supports up to 3 saved build settings</span>
                            </td>
                        </tr>
                    </table>
                </v-card>
            </v-col>
            <v-col cols="2">
                <v-card elevation="2" height="640">
                    <h5>For the Serious DFS Player</h5>
                    <h3>SportsBytez DFS Pro Tier</h3>
                    <div class="desc">
                        Access to the SportsBytez DFS Lineup Optimizers with the ability to import contests from DraftKings and FanDuel and export your lineups directly. Includes all feature from the Rookie Tier with more builds allowed!
                    </div>
                    <p class="price">
                        <strong>$25</strong> / month
                    </p>
                    <v-btn color="primary" class="subscribe-btn" @click="subscribe('price_1Pnk7yIqoDcb4FVwAXhcxUM2')">
                        Subscribe
                    </v-btn>
                    <p class="benefits">
                        This tier includes:
                    </p>
                    <table class="feature-table">
                        <tr>
                            <td class="icon-cell">
                                <v-icon
                                    small
                                    color="green darken-2"
                                >
                                    mdi-check-circle
                                </v-icon>
                            </td>
                            <td class="desc-cell">
                                <span>All features from the Rookie Tier</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="icon-cell">
                                <v-icon
                                    small
                                    color="green darken-2"
                                >
                                    mdi-check-circle
                                </v-icon>
                            </td>
                            <td class="desc-cell">
                                <span>Generate up to 500 lineups through the optimizer</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="icon-cell">
                                <v-icon
                                    small
                                    color="green darken-2"
                                >
                                    mdi-check-circle
                                </v-icon>
                            </td>
                            <td class="desc-cell">
                                <span>Supports up to 10 saved build settings</span>
                            </td>
                        </tr>
                    </table>
                </v-card>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>
    </div>
  </template>
  
  <script>
  import { loadStripe } from '@stripe/stripe-js';
  import SharedRestClient from '@/services/SharedRestClient';

  export default {
    name: 'Subscriptions',
    data() {
      return {
        stripe: null,
        loadingLinkError: false,
      };
    },
    async created() {
        this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
        // if the user is already on a paid tier, deeplink them to the upgrade plan page on hosted stripe configuration
        if (this.hasPaidTier) {
            SharedRestClient.subscriptions()
                .updateSubscription()
                .then((response) => {
                    window.open(response.data.url, "_self");
                });
        }
    },
    computed: {
        stripeUserId() {
            return this.$auth0.user['https://dfs.sportsbytez.com/stripeId'];
        },
        email() {
            return this.$auth0.user['email'];
        },
        hasPaidTier() {
            return this.$auth0.user[process.env.VUE_APP_AUTH0_ROLES].includes('tierPro') ||
                this.$auth0.user[process.env.VUE_APP_AUTH0_ROLES].includes('tierRookie');
        },
    },
    methods: {
        async subscribe(priceId) {
            this.loadingLinkError = false;
            SharedRestClient.subscriptions()
                .createSubscription({
                    customerId: this.stripeUserId,
                    priceId: priceId,
                })
                .then((response) => {
                    this.stripe.redirectToCheckout({
                        sessionId: response.data.id,
                    });
                })
                .catch(() => this.loadingLinkError = true);
        }
    }
  };
  </script>
  
<style scoped>
div.pricing-table {
    margin-top: 50px;
}
div.v-card {
    padding: 10px 30px;
}
h3 {
    margin-top: 10px;
}
div.desc {
    margin-top: 6px;
    font-size: 14px;
    font-weight: 300;
    height: 110px;
}
p.price {
    font-size: 20px;
}
.subscribe-btn {
    width: 100%;
}
p.benefits {
    margin-top: 20px;
}
td.icon-cell {
    vertical-align: top; 
    padding: 2px; 
    padding-right: 8px;
}
td.desc-cell {
    vertical-align: top; 
    padding: 2px;
    font-size: 14px;
}
</style>
