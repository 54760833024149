<template>
    <v-container style="padding: 20px; width: 60%;">
      <v-row style="margin-top: 2em;">
        <h1 style="margin: auto;">Invite to Beta Test Lineup Optimizers</h1>
        <p style="width: 72%; padding: 20px; margin: auto;">
            Welcome to the beta testing group for my Lineup Optimizer application for NFL & MLB DFS Contests! I appreciate your interest in this application and especially your willingness to help test this out. I've been building this for over 3 years now and it is finally at a point where
            I would like to share it with more people. I hope it can help you win the jackpot in some of your DFS contests!
        </p>
      </v-row>
      <v-row>
        <p style="width: 72%; padding: 20px; margin: auto;">
            <strong>How to get started</strong><br />
            <a @click="sendRequest">Just click this link to send a request for access.</a> You should get access shortly after clicking the link and you should receive an email to let you know when access was granted. 
            Once you get an email telling you that you have access you can then use either of the following links below to access the optimizer. Links to optimizer:
            <ul>
                <li><a href="/baseball">MLB Optimizer</a></li>
                <li><a href="/football">NFL Optimizer</a></li>
            </ul>
        </p>
      </v-row>
      <v-row>
        <p style="width: 72%; padding: 20px; margin: auto;">
            <strong>I have access, now what do I do?</strong><br />
            If you have access now and you aren't sure what to do next, don't worry! Optimizers can be quite complicated and some can be extremely daunting to use if you aren't familiar. Just start simple! Here is the first thing you can do if you are new
            to lineup optimizers:
            <ul style="margin-top: 8px;">
                <li>While on an optimizer page, click the BUILDS tab. You should see a blue button named BUILD LINEUPS. Just click that button! Eventually you will see the optimizer give you a bunch of different lineups you could use in your contest.</li>
                <li>That's it! You could take one (or more) of those lineups and go add them to a contest you are playing in! The optimizer gives you optimal lineups based on the projections and rules you give it. We haven't given any rules yet so it just uses the projections it has available to build optimal lineups.</li>
                <li>
                    Everything else that you can change in the optimizer is giving it rules in how to construct the lineups. At this point the best thing to do is just experiment. Try going to the PROJECTIONS tab and changing the projections of some players. You can also click the thumbs up/down icons to LIKE/DISLIKE players. Change some exposures to players and see how the optimizer adjusts in the lineups that it builds.
                </li>
            </ul>
        </p>
        <p style="width: 72%; padding: 20px; margin: auto;">
            If you do have experience with other optimizers I hope that you find some of the features pretty powerful and easy to use and that the layout is intuitive. The Rules Builder is especially powerful in building targeted rules for your lineups. Please do experiment with these features
            if you are seasoned optimizer user and see what you can come up with!
        </p>
      </v-row>
      <v-row>
        <p style="width: 72%; padding: 20px; margin: auto;">
            <strong>How does this help me?</strong><br />
            Your probably thinking, can't I just go and enter a lineup that I like into a contest and be done? How do this help me win contests? And that is a perfectly valid question! If all your doing is playing a few linups a day/night and you have a good idea of who you want to play, then you might be better off just manually
            entering your lineups and be done with it. But if you aren't sure who the good players are or you need some help finding good budget value players to fit into your lineup, the optimizer can absolutely help you with that.  Even better, now imagine you are multi entering into contests and you have 50 or 100 lineups. 
            It's not very practical to manually create and adjust all those lineups multiple times. This is where the power and magic of an optimizer can help. DFS Contests
            are about odds and getting as many dart throws at a winning lineup as possible. On top of that, projections can change leading up to a contest depending on player availability and other factors. Players get designated to not play close to contest lock and you could be left scrambling to update those 50 or 100 lineups
            manually. This optimizer handles all of that for and makes it extremely easy to update your lineups in seconds! It can give you a download file that you can just upload to DraftKings or FanDuel to update your lineups!
        </p>
        <p style="width: 72%; padding: 20px; margin: auto;">
            All this to say that this just scratches the surface of the benefits a lineup optimizer can provide. I plan to add some guides on how to use certain aspects of the optimzer in the near future. Until then, if you have any questions on how things work or how you can do certain things please feel free to ask!
        </p>
      </v-row>
      <v-row>
        <p style="width: 72%; padding: 20px; margin: auto;">
            <strong>How to help improve the optimizer!</strong><br />
            In order to help me test this out and improve on the functionality and features the optimizer can provide, it would be awesome if you could do the following things:
            <ul style="margin-top: 8px;">
                <li>Just use the site! The more you use it the more valuable data I get on how players like you interact with it. Use it how you want to use it, it's a tool for you to be successful!</li>
                <li>
                    Don't be afraid to ask questions, send feedback, report issues, or just chat about the app! All of that stuff is insanely helpful for me to improve this site and any feedback or conversations you want to have about the 
                    site will help tremendously. Here are a few ways you can get in contact with me:
                    <ul style="margin-top: 4px;">
                        <li>
                            Use the feedback link at the bottom of any page. Just type in your feedback, question, or issue and it gets sents directly to me!
                        </li>
                        <li>
                            Shoot me an email at <a href="mailto: nate@sportsbytez.com">nate@sportsbytez.com</a>! I'll respond to every email as fast as I can.
                        </li>
                        <li>
                            There is a link to our Discord channel at the top of page. Join our channel! This is a great way to get a near immediate response from me and also chat with others that use this site.
                        </li>
                        <li>
                            If you have my number, shoot me a text and let me know what you think!
                        </li>
                    </ul>
                </li>
            </ul>
        </p>
      </v-row>
      <v-row>
        <p style="width: 72%; padding: 20px; margin: auto;">
            <strong>Optimizer Updates!</strong><br />
            I will be updating the site very frequently, some updates you may notice changes but many of them you probably won't. However, any update I make has the potential to break something. If you find something broken or not working as you expect
            please reach out to me right away. I may know about it already, but in the event I do not reaching out and letting me know will get it fixed quicker! Use any of the methods mentioned above of letting me know about something that is broken!
            I will try and get into the habit of posting updates and what changed in those updates in Discord if any of you are interested in following along with the updates!            
        </p>
      </v-row>
    </v-container>
</template>

<script>
    import SharedRestClient from '@/services/SharedRestClient';

    export default {
        name: "Invite",
        data: function () {
            return {
            };
        },
        methods: {
            sendRequest() {
                SharedRestClient.user()
                    .requestBetaAccess('Both')
                    .then(() => this.$root.$emit('snackbar', {
                                text: 'Optimizer access has been requested. You will receive an email access has been granted!',
                                color: 'success'
                            }));
            }
        }
    };
</script>

<style scoped>
.m-auto {
  margin: auto;
  margin-top: 20px;
}
</style>