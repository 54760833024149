<template>
    <div class="top-margin">
        <v-row>
            <v-col cols="9" v-if="hasRules">
                <RuleView v-for="rule in rules" 
                    :key="rule.id" 
                    :rule="rule" 
                    :games="games"
                    v-on="$listeners" />
            </v-col>
            <v-col cols="9" v-else>
                <v-alert
                    border="top"
                    colored-border
                    type="info"
                    elevation="2"
                >
                    You do not have any rules defined. Use the Rules Builder to add one or more group rules.
                </v-alert>
            </v-col>
            <v-col cols="3">
                <RulesBuilder :odds="odds"
                    :projections="projections"
                    :provider="provider"
                    :slate="slate"
                    v-on="$listeners" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import RulesBuilder from './RulesBuilder.vue';
import RuleView from './RuleView.vue';

export default {
    name: "GroupRules",
    components: {
        RulesBuilder,
        RuleView
    },
    props: {
        rules: {
            type: Array,
            required: true
        },
        odds: {
            type: Array,
            required: true
        },
        projections: {
            type: Array,
            required: true
        },
        provider: {
            type: Object,
            required: true
        },
        slate: {
            type: Object,
            required: true
        }
    },
    data: function () {
        return {
        };
    },
    computed: {
        hasRules() {
            return this.rules && this.rules.length > 0;
        },
        games() {
            return this.odds.map(o => o.game);
        }
    }
};
</script>
<style scoped>
.top-margin {
  margin-top: 20px;
}
</style>