<template>
    <v-container style="padding: 20px; width: 60%;">
      <v-row style="margin-top: 2em;">
        <h1 style="margin: auto;">Dominate Your DFS Contests with Our Powerful Lineup Optimizer!</h1>
        <p style="width: 72%; padding: 20px; margin: auto;">
            DFS Players today need an optimizer to truly unlock the potential in their lineups and take down large contests. 
            Our optimizer can help you get the most out of your DFS Lineups whether you are playing large GPP tournaments or just a couple lineups with friends. 
            Our goal is to provide a powerful optimizer that DFS players at any level can use to improve their lineups, at an affordable price that won't eat into your winnings! Try it now for free!
        </p>
      </v-row>
      
      <v-row style="text-align: center;">
        <p style="margin: auto;">
            <v-icon>mdi-football</v-icon>
            <a href="/football" style="margin-left: 4px;">Get started with our NFL Optimizer now!</a>
        </p>
        <br />
        <p style="margin: auto;">
            <v-icon>mdi-baseball-outline</v-icon>
            <a href="/baseball" style="margin-left: 4px;">Get started with our MLB Optimizer now!</a>
        </p>
      </v-row>

      <v-row>
        <h3 style="width: 70%; margin: auto; margin-top: 3em;">Features of Our Lineup Optimizer</h3>
      </v-row>

      <v-row style="width: 70%; margin: auto; margin-top: 1em;">
        <v-col cols="1" style="text-align: center;">
            <v-icon style="font-size: 60px;">mdi-database-arrow-up-outline</v-icon>
        </v-col>
        <v-col cols="5" style="padding-right: 25px;">
            <h4>Constantly Updated Projections</h4>
            <p>
                Updated projections are made available to the optimizer immediately so you can be sure your lineups are always using the most accurate projections possible!
            </p>
        </v-col>
        <v-col cols="1" style="text-align: center;">
            <v-icon style="font-size: 60px;">mdi-pencil-ruler</v-icon>
        </v-col>
        <v-col cols="5" style="padding-right: 25px;">
            <h4>Build Powerful Rules</h4>
            <p>
                Our powerful rules builder allows you to apply specific rules and build your lineups exactly the way you want them!
            </p>
        </v-col>
      </v-row>
      <v-row style="width: 70%; margin: auto; margin-top: 1em;">
        <v-col cols="1" style="text-align: center;">
            <v-icon style="font-size: 60px;">mdi-history</v-icon>
        </v-col>
        <v-col cols="5" style="padding-right: 25px;">
            <h4>Late Swapping Supported</h4>
            <p>
                Our optimizer supports late swapping, so you can be sure no matter when the games in your lineups start, you will have the best possible players in those lineups!
            </p>
        </v-col>
        <v-col cols="1" style="text-align: center;">
            <v-icon style="font-size: 60px;">mdi-poll</v-icon>
        </v-col>
        <v-col cols="5" style="padding-right: 25px;">
            <h4>Create Custom Stacks</h4>
            <p>
                Play the stacks you want to play in your lineup! Our powerful stack builder allows you to build custom stacking rules in your lineups!
            </p>
        </v-col>
      </v-row>
      <v-row style="width: 70%; margin: auto; margin-top: 1em;">
        <v-col cols="1" style="text-align: center;">
            <v-icon style="font-size: 60px;">mdi-upload-multiple</v-icon>
        </v-col>
        <v-col cols="5" style="padding-right: 25px;">
            <h4>Multi-Lineup Support in DraftKings and FanDuel</h4>
            <p>
                Playing 150 max contests? No problem! Our optimizer helps you fill out those contest entries and get them uploaded to the site you are playing in just a few clicks!
            </p>
        </v-col>
        <v-col cols="1" style="text-align: center;">
            <v-icon style="font-size: 60px;">mdi-swap-vertical</v-icon>
        </v-col>
        <v-col cols="5" style="padding-right: 25px;">
            <h4>Adjust Exposures to Players</h4>
            <p>
                Adjust exposures to specific players so you are playing the exact amount of a player in your lineups that you want!
            </p>
        </v-col>
      </v-row>
    </v-container>
</template>
  
<script>
    export default {
        name: "Default",
        data: function () {
            return {
            };
        }
    };
</script>

<style scoped>
.m-auto {
  margin: auto;
  margin-top: 20px;
}
</style>