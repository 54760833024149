var IconLockColumn = {
    text: "",
    value: "icon",
    width: "150px",
    sortable: false
};

var PlayerNameColumn = {
    text: "Player",
    value: "name",
    sortable: false,
    divider: true
};

var StatusColumn = {
    text: "Status",
    value: "rosterStatus",
    width: "3%",
    divider: true
};

var SalaryColumn = {
    text: "Salary",
    value: "salary.salary",
    width: "3%",
    divider: true
};

var SalaryPositionColumn = {
    text: "Position",
    value: "salary.rosterPosition",
    width: "4%",
    divider: true
};

var PlayerPositionColumn = {
    text: "Position",
    value: "player.position",
    width: "4%",
    divider: true
};

var TeamColumn = {
    text: "Team",
    value: "team.abbreviation",
    width: "3%",
    divider: true
};

var OpponentColumn = {
    text: "Opponent",
    value: "opponent",
    width: "4%",
    sortable: false,
    divider: true
};

var ProjectedOwnColumn = {
    text: "Own%",
    tooltip: "Projected Ownership %",
    value: "projectedPoints.projectedOwnership",
    width: "3%",
    divider: true
};

var ProjectedPointsColumn = {
    text: "Points",
    tooltip: "Projected Fantasy Points",
    value: "projectedPoints.projectedPoints",
    width: "3%",
    divider: true
};

var CustomProjectedPointsColumn = {
    text: "My Proj",
    value: "customProjectedPoints.projectedPoints",
    width: "100px",
    divider: true
};

var ValueColumn = {
    text: "Value",
    value: "value",
    width: "3%",
    divider: true
};

var MinExposureColumn = {
    text: "Min Exp",
    tooltip: "Minimum Exposure",
    value: "minExposure",
    width: "96px",
    divider: true
};

var MaxExposureColumn = {
    text: "Max Exp",
    tooltip: "Maximum Exposure",
    value: "maxExposure",
    width: "96px"
};


var InningsPitched = {
    text: "IPs",
    tooltip: "Innings Pitched per Game",
    value: "stats.pitchInningsPitchedPerGame",
    width: "72px",
    divider: true
};

var Strikeouts = {
    text: "Ks",
    tooltip: "Strikeouts per Game",
    value: "stats.pitchStrikeoutsPerGame",
    width: "72px",
    divider: true
};

var Outs = {
    text: "Out",
    tooltip: "Outs per Game",
    value: "stats.pitchOutsPerGame",
    width: "72px",
    divider: true
};

var EarnedRuns = {
    text: "ERA",
    tooltip: "Earned Run Average",
    value: "stats.pitchERA",
    width: "72px",
    divider: true
};

var Hits = {
    text: "Hit",
    tooltip: "Hits per Game",
    value: "stats.batHitsPerGame",
    width: "72px",
    divider: true
};

var Doubles = {
    text: "2B",
    tooltip: "Doubles per Game",
    value: "stats.batDoublesPerGame",
    width: "72px",
    divider: true
};

var Triples = {
    text: "3B",
    tooltip: "Triples per Game",
    value: "stats.batTriplesPerGame",
    width: "72px",
    divider: true
};

var HomeRuns = {
    text: "HR",
    tooltip: "Home Runs per Game",
    value: "stats.batHomeRunsPerGame",
    width: "72px",
    divider: true
};

var Rbis = {
    text: "RBI",
    tooltip: "RBIs per Game",
    value: "stats.batRbisPerGame",
    width: "72px",
    divider: true
};

var Runs = {
    text: "Run",
    tooltip: "Scored Runs per Game",
    value: "stats.batRunsPerGame",
    width: "72px",
    divider: true
};

var Slugging = {
    text: "Slug",
    tooltip: "Slugging Percentage",
    value: "stats.batSluggingPercentage",
    width: "72px",
    divider: true
}


export {
    IconLockColumn,
    PlayerNameColumn,
    StatusColumn,
    SalaryColumn,
    SalaryPositionColumn,
    PlayerPositionColumn,
    TeamColumn,
    OpponentColumn,
    ProjectedOwnColumn,
    ProjectedPointsColumn,
    CustomProjectedPointsColumn,
    ValueColumn,
    MinExposureColumn,
    MaxExposureColumn,
    InningsPitched,
    Strikeouts,
    Outs,
    EarnedRuns,
    Hits,
    Doubles,
    Triples,
    HomeRuns,
    Rbis,
    Runs,
    Slugging,
};