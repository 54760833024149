<template>
    <v-container style="padding: 20px; width: 60%;">
        <h3>Privacy Policy</h3>
        <h3>Effective Date: 8/13/2024</h3>

        <br />
        <h4>1. Introduction</h4>
        Welcome to SportsBytez DFS Optimizer (the “Website”). Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our Website and services.

        <p />
        <h4>2. Information We Collect</h4>
        <h5>a. Personal Information</h5>
        When you register on our Website, we may collect personal information such as your name, email address, and any other information you provide.
        <h5>b. Usage Data:</h5>
        We may collect information on how the Website is accessed and used. This usage data may include information such as your computer's Internet Protocol (IP) address, browser type, browser version, the pages of our Website that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.
        <h5>b. Cookies and Tracking Technologies:</h5>
        We use cookies and similar tracking technologies to track the activity on our Website and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some or all portions of our Website.

        <p />
        <h4>3. How We Use Your Information</h4>
        We use the collected information for various purposes:
        <ul>
            <li>To provide and maintain our Website</li>
            <li>To notify you about changes to our Website</li>
            <li>To allow you to participate in interactive features of our Website when you choose to do so</li>
            <li>To provide customer support</li>
            <li>To gather analysis or valuable information so that we can improve our Website</li>
            <li>To monitor the usage of our Website</li>
            <li>To detect, prevent, and address technical issues</li>
        </ul>

        <p />
        <h4>4. Sharing Your Information</h4>
        We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide users with advance notice. This does not include Website hosting partners and other parties who assist us in operating our Website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.

        <p />
        <h4>5. Security of Your Information</h4>
        We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. However, no method of transmission over the Internet, or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.

        <p />
        <h4>6. Your Data Protection Rights</h4>
        Depending on your location, you may have the following rights regarding your personal data:
        <ul>
            <li>The right to access – You have the right to request copies of your personal data.</li>
            <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate or complete information you believe</li>
        </ul>
    </v-container>
</template>

<script>
    export default {
        name: "PrivacyPolicy",
        data: function () {
            return {
            };
        },
        methods: {
        }
    };
</script>

<style scoped>
.m-auto {
  margin: auto;
  margin-top: 20px;
}
</style>