<template>
    <v-container>
      <v-row>
        <v-alert class="m-auto">
          You have been logged out of the system.
        </v-alert>
      </v-row>
    </v-container>
</template>
  
<script>
    export default {
        name: "LoggedOut",
        data: function () {
            return {
            };
        }
    };
</script>

<style scoped>
.m-auto {
  margin: auto;
  margin-top: 20px;
}
</style>