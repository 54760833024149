<template>
    <v-container fluid>
      <div v-if="!userHasAccessRole">
        <NoPageAccess />
      </div>
      <div v-else-if="teamsLoaded">
        <v-row>
          <v-col cols="2">
            <YearSelector @change="updateAndGet"
              :selected-year="season"
              :current-year="currentYear"
              :start-year="2010" />
          </v-col>
          <v-col cols="2">
            <WeekSelector v-model="week" @change="getTeamRosters" :year="season" />
          </v-col>
          <v-col cols="2">
            <TeamSelector v-model="selectedTeam" @change="getTeamRosters" />
          </v-col>
          <v-col cols="6">
            <div class="right">
                <v-btn elevation="2" color="primary" @click="triggerProjections" :loading="isTriggering">Trigger Projections</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="isLoading">
            <v-col cols="3">
                <v-skeleton-loader
                    class="mx-auto"
                    type="table"
                ></v-skeleton-loader>
            </v-col>
            <v-col cols="3">
                <v-skeleton-loader
                    class="mx-auto"
                    type="table"
                ></v-skeleton-loader>
            </v-col>
            <v-col cols="3">
                <v-skeleton-loader
                    class="mx-auto"
                    type="table"
                ></v-skeleton-loader>
            </v-col>
            <v-col cols="3">
                <v-skeleton-loader
                    class="mx-auto"
                    type="table"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-row v-if="selectedTeam && !isLoading">
            <v-col cols="12">
                <h2>Roster for {{ selectedTeam.fullName }}</h2>
            </v-col>
            <v-col cols="3">
                <RosterPositionView :roster="qbs" :season="season" :week="week" :team="selectedTeam" />
            </v-col>
            <v-col cols="3">
                <RosterPositionView :roster="rbs" :season="season" :week="week" :team="selectedTeam" />
            </v-col>
            <v-col cols="3">
                <RosterPositionView :roster="wrs" :season="season" :week="week" :team="selectedTeam" />
            </v-col>
            <v-col cols="3">
                <RosterPositionView :roster="tes" :season="season" :week="week" :team="selectedTeam" />
            </v-col>
        </v-row>
      </div>
    </v-container>
  </template>
  
  <script>
  import WeekSelector from "@/components/football/NflWeekSelector";
  import YearSelector from "@/components/shared/YearSelector";
  import TeamSelector from "@/components/football/TeamSelector.vue";
  import RosterPositionView from "@/components/football/TeamRoster/RosterPositionView.vue";
  import NoPageAccess from "@/components/NoPageAccess";
  import RestClient from "@/services/FootballRestClient";

  import { createNamespacedHelpers } from 'vuex';
  const { mapActions, mapState } = createNamespacedHelpers('football');

  export default {
    name: "TeamRosters",
    components: {
      WeekSelector,
      YearSelector,
      TeamSelector,
      RosterPositionView,
      NoPageAccess
    },
    data: function () {
      return {
        isLoading: false,
        isTriggering: false,
        season: null,
        week: null,
        selectedTeam: null,
        teamRoster: null
      };
    },
    mounted: function() {
      this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'football/UPDATE_CURRENT_YEAR') {
                this.season = state.football.currentYear;
                this.getTeamRosters();
            }
            if (mutation.type === 'football/UPDATE_CURRENT_WEEK') {
                this.week = state.football.currentWeek;
                this.getTeamRosters();
            }
        });

      this.loadCurrentValues();
      this.loadTeams();
    },
    computed: {
        teamsLoaded() {
            return this.teams && this.teams.length > 0;
        },
        qbs() {
            if (this.teamRoster)
                return this.teamRoster.filter(r => r.position === "QB");
            return [];
        },
        rbs() {
            if (this.teamRoster)
                return this.teamRoster.filter(r => r.position === "RB");
            return [];
        },
        wrs() {
            if (this.teamRoster)
                return this.teamRoster.filter(r => r.position === "WR");
            return [];
        },
        tes() {
            if (this.teamRoster)
                return this.teamRoster.filter(r => r.position === "TE");
            return [];
        },
        userHasAccessRole() {
            return this.$auth0.user[process.env.VUE_APP_AUTH0_ROLES].includes('admin');
        },
        ...mapState({
            teams: state => state.teams,
            currentYear: state => state.currentYear,
        })
    },
    methods: {
        updateAndGet(value) {
            this.season = value;
            this.getTeamRosters();
        },
        getTeamRosters() {
            if (this.season && this.week && this.selectedTeam) {
                this.isLoading = true;
                RestClient.teams()
                    .getTeamRosters(this.season, this.week, this.selectedTeam.abbreviation)
                    .then((response) => {
                        this.teamRoster = response.data;
                    })
                    .finally(() => this.isLoading = false);
            }
        },
        triggerProjections() {
            if (this.season && this.week) {
                this.isTriggering = true;
                RestClient.projections()
                    .trigger(this.season, this.week)
                    .then(() => {
                        this.$root.$emit('snackbar', {
                            text: 'Projections have been triggered!',
                            color: 'success'
                        });
                    })
                    .finally(() => this.isTriggering = false);
            }
        },
        ...mapActions([
            'loadTeams',
            'loadCurrentValues',
        ])
    }
  };
  </script>
  <style scoped>
  div.right {
    text-align: right;
  }
  </style>