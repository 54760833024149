<template>
    <div class="top-margin">
        <v-row>
            <v-col cols="12">
                <v-alert
                    border="top"
                    colored-border
                    type="info"
                    elevation="2"
                >
                    Build player specific stacks you would like to use in your lineups here.
                </v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-alert border="top"
                    colored-border
                    type="warning"
                    elevation="2"
                    v-if="hasWarnings">
                    <ul>
                        <li v-for="(w, idx) in warnings" :key="idx">{{ w }}</li>
                    </ul>
                </v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-btn
                    class="ma-2"
                    color="success"
                    @click="$emit('create-stack')"
                >
                    <v-icon
                        left
                        dark
                    >
                        mdi-plus-circle
                    </v-icon>
                    Create Stack
                </v-btn>
                <v-btn
                    v-if="isDirty && hasStacks"
                    class="ma-2"
                    color="info"
                    @click="$emit('save')"
                >
                    <v-icon
                        left
                        dark
                    >
                        mdi-content-save-check
                    </v-icon>
                    Save Stack Changes
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <StacksList :stacks="stacks" 
                :projections="projections"
                v-on="$listeners" />
        </v-row>
    </div>
</template>

<script>
import StacksList from './StacksList';

export default {
    name: "StacksBuilder",
    components: {
        StacksList
    },
    props: {
        stacks: {
            type: Array,
            required: true
        },
        projections: {
            type: Array,
            required: true
        },
        isDirty: {
            type: Boolean,
            required: true
        }
    },
    data: function () {
        return {
        };
    },
    computed: {
        hasWarnings: function() {
            return this.warnings && this.warnings.length > 0;
        },
        totalMinExposure: function() {
            return this.stacks.reduce((partialSum, s) => partialSum + parseInt(s.minExposure), 0);
        },
        hasStacks: function() {
            return this.stacks && this.stacks.length > 0;
        },
        // All stack warnings
        warnings: function() {
            const warns = [];
            if (this.totalMinExposure > 100.01)
                warns.push('The total minimum exposure of all your stacks is greater than 100%. This may cause one or more of your stacks to not meet minimum exposures. Reduce your minimum exposures so the total is less than or equal to 100%.');
            return warns;
        }
    }
};
</script>
<style scoped>
.top-margin {
  margin-top: 20px;
}
.padding-10 {
    padding: 10px;
}
.mtb {
    margin-top: 6px;
    margin-bottom: 6px;
}
div.inline {
    display: inline-block;
    width: 300px;
}
div.sm {
    width: 100px;
}
div.action-buttons {
    float: right;
    width: 400px;
    text-align: right;
}
</style>