import * as headers from './ProjectionHeaders';

export default function(position) {
    if (position === 'P') {
        return [
            headers.IconLockColumn,
            headers.PlayerNameColumn,
            headers.SalaryPositionColumn,
            headers.StatusColumn,
            headers.SalaryColumn,
            headers.TeamColumn,
            headers.OpponentColumn,
            headers.ProjectedPointsColumn,
            headers.CustomProjectedPointsColumn,
            headers.ValueColumn,
            headers.ProjectedOwnColumn,
            headers.MinExposureColumn,
            headers.MaxExposureColumn
        ];
    }
    return [
        headers.IconLockColumn,
        headers.PlayerNameColumn,
        headers.SalaryPositionColumn,
        headers.StatusColumn,
        headers.SalaryColumn,
        headers.TeamColumn,
        headers.OpponentColumn,
        // Stat columns here
        headers.InningsPitched,
        headers.Strikeouts,
        headers.Outs,
        headers.EarnedRuns,
        headers.Hits,
        headers.Doubles,
        headers.Triples,
        headers.HomeRuns,
        headers.Rbis,
        headers.Runs,
        headers.Slugging,
        headers.ProjectedPointsColumn,
        headers.CustomProjectedPointsColumn,
        headers.ValueColumn,
        headers.ProjectedOwnColumn,
        headers.MinExposureColumn,
        headers.MaxExposureColumn
    ];
}