<template>
    <div>
        <v-card
            class="mx-auto"
            outlined>
            <v-card-title background="#0C2D48">
                Rules Builder
            </v-card-title>
            <v-card-text>
                <div>
                    <div class="inline" style="width: 100px;">
                        <v-select v-model="groupRuleType" 
                                :items="groupRuleTypeOptions"
                                @change="groupRuleTypeChange"
                                filled
                                dense
                        /> 
                    </div>
                    <div class="inline" style="width: 160px;">
                        <v-select v-model="groupRuleComparer" 
                                :items="groupRuleComparerTypes"
                                item-text="display"
                                item-value="value"
                                filled
                                dense
                        /> 
                    </div>
                    <div class="inline" style="width: 60px;">
                        <v-text-field
                            v-model="groupRuleAmount"
                            type="number"
                            filled
                            dense
                        ></v-text-field>
                    </div> player<span v-if="groupRuleAmount > 1">s</span> <span v-if="groupRuleType === 'If' && groupRuleAmount > 1">are used</span><span v-if="groupRuleType ==='If' && groupRuleAmount <= 1">is used</span>
                </div>
                <div style="margin-top: 10px;">
                    <div class="inline">From:</div>
                    <v-radio-group
                        v-model="groupRuleGroupBy"
                        row>
                        <v-radio
                            label="Team"
                            value="team"
                        ></v-radio>
                        <v-radio
                            label="Game"
                            value="game"
                        ></v-radio>
                    </v-radio-group>
                </div>
                <div style="margin-top: 10px;" v-if="groupRuleGroupBy === 'team'">
                    <div>For 
                        <div class="inline" style="width: 100px;" v-if="groupRuleType === 'Use'">
                            <v-select v-model="groupRuleForType" 
                                    :items="groupRuleForTypeOptions"
                                    filled
                                    dense
                            /> 
                        </div>
                        <span v-else>each</span>
                        team(s):</div>
                    <div>
                        <v-select v-model="groupRuleTeams" 
                                :items="slateTeams"
                                item-value="abbreviation"
                                item-text="fullName"
                                chips
                                multiple
                                filled
                                dense
                        > 
                            <template v-slot:prepend-item>
                                <v-list-item
                                    ripple
                                    @mousedown.prevent
                                    @click="toggleTeams">
                                    <v-list-item-action>
                                        <v-icon :color="groupRuleTeams.length > 0 ? 'indigo darken-4' : ''">
                                            {{ icon }}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            All Teams
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                    </div>
                </div>
                <div style="margin-top: 10px;" v-if="groupRuleGroupBy === 'game'">
                    <div>For 
                        <div class="inline" style="width: 100px;" v-if="groupRuleType === 'Use'">
                            <v-select v-model="groupRuleForType" 
                                    :items="groupRuleForTypeOptions"
                                    filled
                                    dense
                            /> 
                        </div>
                        <span v-else>each</span>
                        game(s):</div>
                    <div>
                        <v-select v-model="groupRuleGames" 
                                :items="slateGames"
                                item-value="gameId"
                                item-text="display"
                                chips
                                multiple
                                filled
                                dense
                        > 
                            <template v-slot:prepend-item>
                                <v-list-item
                                    ripple
                                    @mousedown.prevent
                                    @click="toggleGames">
                                    <v-list-item-action>
                                        <v-icon :color="groupRuleGames.length > 0 ? 'indigo darken-4' : ''">
                                            {{ iconGames }}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            All Games
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                    </div>
                </div>
                <div style="margin-top: 10px;">
                    <div>At Position(s):</div>
                    <div>
                        <v-checkbox
                            v-for="s in slate.playerPositions"
                            :key="s"
                            v-model="groupRulePositions"
                            :label="s"
                            :value="s"
                        ></v-checkbox>
                    </div>
                    <div v-if="isSingleGameSlate">
                        <div>In Roster Spot(s):</div>
                        <div>
                            <v-checkbox
                                v-for="spot in rosterSpotFilters"
                                :key="spot"
                                v-model="groupRuleRosterSpots"
                                :label="spot"
                                :value="spot"
                            ></v-checkbox>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 10px" v-if="groupRuleType === 'If'">
                    <div>
                        <div class="inline" style="width: 60px;">
                            Then Use
                        </div>
                        <div class="inline" style="width: 160px;">
                            <v-select v-model="secondaryRuleComparer" 
                                    :items="groupRuleComparerTypes"
                                    item-text="display"
                                    item-value="value"
                                    filled
                                    dense
                            /> 
                        </div>
                        <div class="inline" style="width: 60px;">
                            <v-text-field
                                v-model="secondaryRuleAmount"
                                type="number"
                                filled
                                dense
                            ></v-text-field>
                        </div> player<span v-if="groupRuleAmount > 1">s</span> on
                        <div class="inline" style="width: 140px;">
                            <v-select v-model="secondaryTeam" 
                                    :items="secondaryTeamOptions"
                                    filled
                                    dense
                            /> 
                        </div> team
                    </div>
                    <div style="margin-top: 10px;">
                        <div>At Position(s):</div>
                        <div>
                            <v-checkbox
                                v-for="s in slate.playerPositions"
                                :key="s"
                                v-model="secondaryPositions"
                                :label="s"
                                :value="s"
                            ></v-checkbox>
                        </div>
                    </div>
                    <div v-if="isSingleGameSlate">
                        <div>In Roster Spot(s):</div>
                        <div>
                            <v-checkbox
                                v-for="spot in rosterSpotFilters"
                                :key="spot"
                                v-model="secondaryRosterSpots"
                                :label="spot"
                                :value="spot"
                            ></v-checkbox>
                        </div>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" @click="addRule">
                    Add Rule
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    name: "RulesBuilder",
    props: {
        odds: {
            type: Array,
            required: true
        },
        projections: {
            type: Array,
            required: true
        },
        provider: {
            type: Object,
            required: true
        },
        slate: {
            type: Object,
            required: true
        }
    },
    data: function () {
        return {
            groupRuleType: 'Use',
            groupRuleComparer: '=',
            groupRuleAmount: 1,
            groupRuleGroupBy: 'team',
            groupRuleForType: 'Each',
            groupRuleTeams: [],
            groupRuleGames: [],
            groupRulePositions: [],
            groupRuleRosterSpots: [],
            secondaryRuleComparer: '=',
            secondaryRuleAmount: 1,
            secondaryTeam: 'Same',
            secondaryPositions: [],
            secondaryRosterSpots: []
        };
    },
    mounted() {
        // initialize teams by having them all selected at the start
        this.groupRuleTeams = this.slateTeams.map(t => t.abbreviation);
        this.groupRulePositions = this.slate.playerPositions.map(p => p);
        this.groupRuleRosterSpots = this.rosterSpotFilters.map(p => p);
        this.secondaryRosterSpots = this.rosterSpotFilters.map(p => p);

        // when the selected slate changes, update the default selections for teams and positions
        this.$store.subscribe((mutation) => {
            if (mutation.type.includes('UPDATE_SLATE')) {
                this.groupRulePositions = this.slate.playerPositions.map(p => p);
            }
            if (mutation.type.includes('UPDATE_PROJECTIONS')) {
                this.groupRuleTeams = this.slateTeams.map(t => t.abbreviation);
            }
        });
    },
    computed: {
        groupRuleTypeOptions() {
            return ['Use','If'];
        },
        groupRuleForTypeOptions() {
            return ['Each','Any'];
        },
        secondaryTeamOptions() {
            return ['Same', 'Opposing'];
        },
        groupRuleComparerTypes() {
            return [
                { value: '=', display: 'Exactly' },
                { value: '>=', display: 'At least' },
                { value: '<=', display: 'No more than' },
            ]
        },
        slateTeams() {
            var distinct = [...new Map(this.projections.map(p => [p.team['abbreviation'], p.team]).values())];
            distinct.sort();
            return distinct.map(v => v[1]);
        },
        isSingleGameSlate() {
            return this.slateTeams.length <= 2;
        },
        slateGames() {
            // We have access to the games in the slate odds
            return this.odds.map(o => o.game);
        },
        allTeamsSelected () {
            return this.groupRuleTeams.length === this.slateTeams.length
        },
        someTeamsSelected () {
            return this.groupRuleTeams.length > 0 && !this.allTeamsSelected
        },
        icon () {
            if (this.allTeamsSelected) return 'mdi-close-box'
            if (this.someTeamsSelected) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        allGamesSelected () {
            return this.groupRuleGames.length === this.slateGames.length
        },
        someGamesSelected () {
            return this.groupRuleGames.length > 0 && !this.allGamesSelected
        },
        iconGames () {
            if (this.allGamesSelected) return 'mdi-close-box'
            if (this.someGamesSelected) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        rosterSpotFilters() {
            return this.slate.rosterPositions;
        }
    },
    methods: {
        groupRuleTypeChange() {
            this.groupRuleForType = 'Each';
        },
        clear() {
            this.groupRuleType = 'Use';
            this.groupRuleForType = 'Each';
            this.groupRuleComparer = '=';
            this.groupRuleAmount = 1;
            this.groupRuleGroupBy = 'team';
            this.groupRuleTeams = this.slateTeams.map(t => t.abbreviation);
            this.groupRuleGames = [];
            this.groupRulePositions = this.slate.playerPositions.map(p => p);
            this.groupRuleRosterSpots = this.rosterSpotFilters.map(p => p);
            this.secondaryRuleComparer = '=';
            this.secondaryRuleAmount = 1;
            this.secondaryTeam = 'Same';
            this.secondaryPositions = [];
            this.secondaryRosterSpots = this.rosterSpotFilters.map(p => p);
        },
        addRule() {
            var rule = {
                id: uuidv4(),
                groupRuleType: this.groupRuleType,
                groupRuleForType: this.groupRuleForType,
                groupRuleComparer: this.groupRuleComparer,
                groupRuleAmount: this.groupRuleAmount,
                groupRuleGroupBy: this.groupRuleGroupBy,
                groupRuleTeams: this.groupRuleTeams ?? [],
                groupRuleGames: this.groupRuleGames ?? [],
                groupRulePositions: this.groupRulePositions ?? []
            };
            if (this.groupRuleType === 'If') {
                rule.secondaryRuleComparer = this.secondaryRuleComparer;
                rule.secondaryRuleAmount = this.secondaryRuleAmount;
                rule.secondaryTeam = this.secondaryTeam;
                rule.secondaryPositions = this.secondaryPositions;
            }
            if (this.isSingleGameSlate) {
                rule.groupRuleRosterSpots = this.groupRuleRosterSpots;
                rule.secondaryRosterSpots = this.secondaryRosterSpots;
            }
            this.$emit('add-rule', rule);
            this.clear();
        },
        toggleTeams () {
            this.$nextTick(() => {
                if (this.allTeamsSelected) {
                    this.groupRuleTeams = []
                } else {
                    this.groupRuleTeams = this.slateTeams.map(t => t.abbreviation);
                }
            });
        },
        toggleGames () {
            this.$nextTick(() => {
                if (this.allGamesSelected) {
                    this.groupRuleGames = []
                } else {
                    this.groupRuleGames = this.slateGames.map(g => g.id);
                }
            });
        },
    }
};
</script>
<style scoped>
div.v-card__title {
    background: #0C2D48;
    color: white;
    padding: 5px;
}
div.inline {
    display: inline-block;
    margin: 0 3px;
}
div.v-card__text {
 padding-top: 10px;
}
.v-input.v-input--checkbox {
    display: inline-block;
    width: 94px;
}
</style>
<style>
.theme--light.v-chip:not(.v-chip--active) {
    background: #145DA0;
    color: white;
}
.theme--light.v-chip.v-chip--active {
    background: #2E8BC0;
    color: white;
}
.v-select.v-input--dense .v-chip {
    margin: 2px 4px;
}
</style>