<template>
    <tr>
        <td>{{ position }}</td>
        <td>{{ getPlayerName(player) }}</td>
        <td>{{ player.team.abbreviation }}</td>
        <td>{{ player.opponent.abbreviation }}</td>
        <td>{{ player.salary.salary }}</td>
        <!--<td>{{ Math.round(player.projectedPoints.projectedOwnership) }}%</td>-->
        <td>{{ player.customProjectedPoints.projectedPoints.toFixed(2) }}</td>
        <td v-if="backtesting">{{ player.actualFantasyPointsScored ? player.actualFantasyPointsScored.toFixed(2) : 0 }}</td>
    </tr>
  </template>
  
  <script>
  export default {
    name: "LineupViewRow",
    data: () => ({}),
    props: {
        position: {
            type: String,
            required: true
        },
        player: {
            type: Object,
            required: true,
        },
        backtesting: {
            type: Boolean,
            default: false
        }
    },
    methods: {
      getPlayerName(projection) {
        if (projection.player)
          return projection.player.name;
        return projection.team.mascot;
      }
    }
  };
  </script>
  <style scoped>
  </style>