import { getInstance } from '@/auth0-plugin';

export default {
  namespaced: false,
  state: {
    userAuthToken: null,
  },
  getters: {
    maxBuildsAllowed () {
        const authService = getInstance();
        const roles = authService.user[process.env.VUE_APP_AUTH0_ROLES];
        if (roles?.includes('tierPro'))
            return 500;
        if (roles?.includes('tierRookie'))
            return 100;
        return 10;
    }
  },
  mutations: {
    SET_AUTH_TOKEN(state, payload) {
      state.userAuthToken = payload;
      localStorage.setItem('bearer_token', payload);
    }
  },
  actions: {
  }
}
