<template>
    <div class="myc-container">
        <v-alert type="info" border="top" colored-border>
            <span v-if="!hasContests">You do not have any contests for this slate.</span> You can download a lineups file for {{ provider.name }} <a :href="providerLink" target="_blank">by clicking here</a> and upload it to add the contests you are playing for this slate of games.
        </v-alert>
        <v-row class="upload-row">
            <v-col cols="5">
                <v-file-input ref="uploadFileInput"
                    @click:clear="$emit('clear-file')"
                    @change="$emit('file-changed')"
                    accept=".csv" 
                    :error-messages="uploadErrors"
                    label="Lineups File"
                    :rules="rules"
                    v-model="uploadFile"
                    prepend-icon="mdi-file-upload"
                    :loading="uploading"
                    show-size>
                </v-file-input>
            </v-col>
            <v-col cols="1">
                <v-btn elevation="2" @click="$emit('upload', uploadFile, uploaded)" :loading="uploading" :disabled="uploading || !hasFile || hasRulesErrors" color="primary">
                    Upload File
                    <template v-slot:loader>
                        <span>Uploading...</span>
                    </template>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <MyContestsView v-if="hasContests"
                    :contests="contests"
                    :view-contest-id="viewContestId"
                    v-on="$listeners" />
            </v-col>
            <v-col cols="6">
                <slot name="lineup-view"></slot>
            </v-col>
        </v-row>
    </div>
</template>
  
<script>
    import MyContestsView from './MyContestsView.vue'

    export default {
        name: "MyContests",
        components: {
            MyContestsView
        },
        props: {
            provider: {
                type: Object,
                required: true
            },
            contests: {
                type: Array,
                required: true
            },
            uploading: {
                type: Boolean,
                required: true
            },
            uploadErrors: {
                type: Array,
                required: true
            },
            viewContestId: {
                required: false
            }
        },
        data: function () {
            return {
                uploadFile: null,
                rules: [
                    value => !value || value.size < 30000000 || 'File size must be less than 30 MB!',
                ],
            };
        },
        computed: {
            providerLink: function() {
                if (this.provider.id === 1)
                    return 'https://www.draftkings.com/entry/upload';
                return 'https://www.fanduel.com/upcoming';
            },
            hasContests: function() {
                return this.contests && this.contests.length > 0;
            },
            hasFile: function() {
                return this.uploadFile;
            },
            hasRulesErrors: function() {
                return this.$refs.uploadFileInput.hasError;
            },
        },
        methods: {
            // Method called when upload was successful
            uploaded() {
                this.uploadFile = null;
            },
        }
    };
</script>
<style scoped>
    div.myc-container {
        margin: 20px 10px;
    }
    div.upload-row::v-deep div.v-text-field__details {
        display: flex;
    }
    a {
        color:aliceblue;
    }
</style>