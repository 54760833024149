<template>
    <GroupRules :rules="rules"
        :odds="odds"
        :provider="provider"
        :projections="projections"
        :slate="slate"
        @delete-rule="deleteRule"
        @add-rule="addRule" />
</template>

<script>
import GroupRules from '@/components/shared/GroupRules/GroupRules.vue';

import { createNamespacedHelpers } from 'vuex';
const { mapState, mapMutations, mapActions } = createNamespacedHelpers('football');

export default {
    name: "FootballGroupRules",
    components: {
        GroupRules
    },
    data: function () {
        return {
        };
    },
    computed: {
        ...mapState({
            rules: state => state.settings.rules,
            provider: state => state.providers.selected,
            projections: state => state.projections.data,
            slate: state => state.slates.selected,
            odds: state => state.odds.data
        })
    },
    methods: {
        deleteRule: function(rule) {
            this.deleteRule(rule);
            this.saveBuildSettings();
            this.$gtag.event('Delete Rule');
        },
        addRule: function(rule) {
            this.addRuleToStore(rule);
            this.saveBuildSettings();
            this.$gtag.event('Add Rule');
        },
        ...mapActions({
            saveBuildSettings: 'saveBuildSettings'
        }),
        ...mapMutations({
            deleteRule: 'DELETE_RULE',
            addRuleToStore: 'ADD_RULE'
        })
    }
};
</script>
<style scoped>
.top-margin {
  margin-top: 20px;
}
</style>