import * as headers from './ProjectionHeaders';

export default [
    headers.IconLockColumn,
    headers.PlayerNameColumn,
    headers.PlayerPositionColumn,
    headers.StatusColumn,
    headers.SalaryColumn,
    headers.TeamColumn,
    headers.OpponentColumn,
    headers.PassYds,
    headers.PassTds,
    headers.RushYds,
    headers.RushTds,
    headers.RecYds,
    headers.RecTds,
    headers.ProjectedPointsColumn,
    headers.CustomProjectedPointsColumn,
    headers.ValueColumn,
    //headers.ProjectedOwnColumn,
    headers.MinExposureColumn,
    headers.MaxExposureColumn
]