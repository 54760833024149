import { render, staticRenderFns } from "./FootballGroupRules.vue?vue&type=template&id=65ab4ab1&scoped=true&"
import script from "./FootballGroupRules.vue?vue&type=script&lang=js&"
export * from "./FootballGroupRules.vue?vue&type=script&lang=js&"
import style0 from "./FootballGroupRules.vue?vue&type=style&index=0&id=65ab4ab1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65ab4ab1",
  null
  
)

export default component.exports