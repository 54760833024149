<template>
    <div>
        <v-row>
            <v-col cols="10">
                <v-btn
                    v-for="filter in showFilters"
                    :key="filter.value"
                    class="ma-2"
                    small
                    :outlined="filter.value !== stackFilter.value"
                    color="primary"
                    @click="stackFilter = filter"
                >
                    {{ filter.display }}
                </v-btn>
            </v-col>
        </v-row>
        <div v-if="stackFilter.value === 'POS'">
            <PositionStackView :lineups="lineups" :teams="distinctTeams" />
        </div>
        <div v-if="stackFilter.value === 'TEAM' && !isSingleGameSlate">
            <TeamStackView :lineups="lineups" :teams="distinctTeams" />
        </div>
        <div v-if="stackFilter.value === 'GAME'">
            <GameStackView :lineups="lineups" :games="games" />
        </div>
    </div>
</template>

<script>
import PositionStackView from './PositionStackView.vue';
import GameStackView from './GameStackView.vue';
import TeamStackView from './TeamStackView.vue';

export default {
    name: "StackExposures",
    components: {
        PositionStackView,
        GameStackView,
        TeamStackView
    },
    props: {
        lineups: {
            type: Array,
            required: true
        },
        games: {
            type: Array,
            required: true
        }
    },
    data: function () {
        return {
            stackFilter: {value: 'GAME', display: 'Game Stacks'}
        };
    },
    computed: {
        // Distinct teams in the lineups
        distinctTeams() {
            var teams = [];
            for (var l of this.lineups)
                for (var t of l.allSpots.map(p => p.team.abbreviation))
                    teams.push(t);   
            return teams = [...new Set(teams)];
        },
        isSingleGameSlate() {
            return this.distinctTeams.length <= 2;
        },
        showFilters() {
            return this.stackFilters.filter(f => f.visible());
        },
        stackFilters() {
            return [
                {value: 'POS', display: 'Position Stacks', visible: () => false},   // Not showing this one until we figure out stack exposures
                {value: 'TEAM', display: 'Team Stacks', visible: () => !this.isSingleGameSlate},
                {value: 'GAME', display: 'Game Stacks', visible: () => true},
            ];
        }
    }
};
</script>
<style scoped>
</style>