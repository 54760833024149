<template>
    <div class="settings-container">
        <v-row>
            <v-col cols="2" style="margin-bottom: 20px;">
                <v-row>
                    <h4>Current Slate Settings</h4>
                </v-row>
                <slot></slot>
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="4">
                <v-card
                    elevation="2"
                    outlined>
                    <v-app-bar
                        flat
                        color="rgba(0, 0, 0, 0)"
                        >
                        <v-app-bar-nav-icon color="white"></v-app-bar-nav-icon>

                        <v-toolbar-title class="text-h6 pl-0">
                            Saved Build Settings
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <SaveBuildSettingsDialog 
                            :savedSettings="savedSettings"
                            v-on="$listeners" />
                    </v-app-bar>

                    <v-card-text>
                        <v-alert
                            v-if="!hasSavedSettings"
                            border="top"
                            colored-border
                            type="info"
                            elevation="2">

                            You do not have any saved build settings. You can save your current build settings by click the button above.
                        </v-alert>

                        <v-list>
                            <template v-for="(setting, index) in savedSettings">
                                <v-divider :key="index"></v-divider>
                                <v-list-item :key="index">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <div class="itm-left">
                                                {{ setting.settingsName }}
                                            </div>
                                            <div class="itm-right">
                                                <v-btn small color="primary" @click="loadToCurrentSettings(setting)">Load To Current Settings</v-btn>
                                                <v-btn small color="error" @click="deleteSavedSettings(setting.settingsName)">Delete Settings</v-btn>
                                            </div>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import SaveBuildSettingsDialog from "./SaveBuildSettingsDialog.vue"

export default {
    name: "BuildSettings",
    components: {
        SaveBuildSettingsDialog
    },
    props: {
        savedSettings: {
            type: Array,
            required: true
        }
    },
    data: function () {
        return {
        };
    },
    computed: {
        hasSavedSettings() {
            return this.savedSettings?.length > 0;
        }
    },
    methods: {
        deleteSavedSettings: function(settingsName) {
            this.$emit('delete-saved-settings', settingsName);
        },
        loadToCurrentSettings: function(settings) {
            this.$emit('load-to-current-settings', settings);
        }
    }
};
</script>
<style scoped>
.settings-container {
  margin-top: 20px;
  margin-left: 20px;
}
div.v-list-item {
    clear: both;
}
div.itm-left {
    width: 40%;
    float: left;
}
div.itm-right {
    width: 55%;
    float: right;
    height: 30px;
    text-align: right;
}
div.itm-right button {
    margin-left: 10px;
}
</style>