<template>
    <v-select
        v-model="selectedValue"
        :items="providers"
        :disabled="disabled"
        item-text="name"
        item-value="id"
        :return-object="true"
        filled
        label="Select Contest Provider"
    >
    </v-select>
</template>

<script>
export default {
    name: "ContestProviderSelector",
    props: {
        providers: {
            type: Array,
            required: true
        },
        selectedProvider: {
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
        };
    },
    computed: {
        selectedValue: {
            get() {
                return this.selectedProvider;
            },
            set(value) {
                this.$gtag.event('change provider', { 
                    'event_category': 'Interaction',
                    'event_label': 'Provider Change',
                    'value': value.abbreviation
                });
                this.$emit('change', value);
            }
        }
    }
};
</script>
<style scoped>
</style>