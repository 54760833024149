<template>
    <v-data-table
        dense
        :headers="headers"
        :items="viewStacks"
        :items-per-page="25"
        :footer-props="footerProps"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="elevation-1 top-margin">
        <template v-slot:[`item.key`]="{ item }">
            {{ item.key }}
        </template>
        <template v-slot:[`item.value`]="{ item }">
            {{ getExposurePercent(item.value) }}% ({{ item.value }})
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: "TeamStackView",
    props: {
        lineups: {
            type: Array,
            require: true
        },
        teams: {
            type: Array,
            required: true
        }
    },
    data: function () {
        return {
            sortBy: 'value',
            sortDesc: true,
            headers: [
                {
                    text: "Stack",
                    value: "key",
                    sortable: false,
                    divider: true
                },
                {
                    text: "Exposure",
                    value: "value",
                    divider: true,
                    width: '10%'
                }
            ],
        };
    },
    computed: {
        isSingleGame() {
            return this.teams.length <= 2;
        },
        viewStacks() {
            return this.isSingleGame ? this.singleGameStacks : this.multiGameStacks;
        },
        singleGameStacks() {
            // This is just position stacks right now
            var stackResults = {};
            this.lineups.forEach(l => {
                var countTeam1 = l.allSpots.filter(p => p.team.abbreviation === this.teams[0]).length;
                var countTeam2 = l.allSpots.filter(p => p.team.abbreviation === this.teams[1]).length;

                // Creating two records for each lineup
                var keyTeam1 = `${this.teams[0]} - ${countTeam1}`;
                var keyTeam2 = `${this.teams[1]} - ${countTeam2}`;
                var key = '';

                if (countTeam1 > countTeam2)
                {
                    key = countTeam2 > 1 ?
                        `${keyTeam1} | ${keyTeam2}` :
                        `${keyTeam1}`;
                }
                else
                {
                    key = countTeam1 > 1 ?
                        `${keyTeam2} | ${keyTeam1}` :
                        `${keyTeam2}`;
                }

                if (!stackResults[key])
                    stackResults[key] = 1;
                else
                    stackResults[key]++;
            });

            return Object.entries(stackResults).map(([key, value]) => {
                return {
                    key,
                    value
                };
            });
        },
        multiGameStacks() {
            // This is just position stacks right now
            var stackResults = {};
            this.lineups.forEach(l => {
                this.teams.forEach(t => {
                    var countTeam = l.allSpots.filter(p => p.team.abbreviation === t).length;
                    var keyTeam = `${t} - ${countTeam}`;
                    if (countTeam > 1) {
                        if (!stackResults[keyTeam])
                            stackResults[keyTeam] = 1;
                        else
                            stackResults[keyTeam]++;
                    }
                });
            });

            return Object.entries(stackResults).map(([key, value]) => {
                return {
                    key,
                    value
                };
            });
        },
        footerProps() {
            return {
                'items-per-page-options': [
                    10,
                    25,
                    50,
                    100,
                    -1
                ]
            };
        }
    },
    methods: {
        getExposurePercent(count) {
            return Math.round(((count / this.lineups.length) * 100).toFixed(1))
        }
    }
};
</script>
<style scoped>
</style>