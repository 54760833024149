<template>
    <v-simple-table
        dense
        class="elevation-1">
        <template v-slot:default>
            <thead>
                <tr>
                    <th v-if="viewOptions?.showCheckbox">
                        <!-- Checkbox column -->
                    </th>
                    <th class="text-left">
                        Contest Id
                    </th>
                    <th class="text-left">
                        Contest Name
                    </th>
                    <th class="text-left" v-if="hasEntryFee">
                        Entry Fee
                    </th>
                    <th class="text-left">
                        # of Entries
                    </th>
                    <th class="text-left" v-if="viewOptions?.showActions"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="contest in contests"
                    :key="contest.contestId">
                    <td v-if="viewOptions?.showCheckbox">
                        <v-checkbox
                            v-model="selectedContestValues"
                            :value="contest.contestId"
                        ></v-checkbox>
                    </td>
                    <td>{{ contest.contestId }}</td>
                    <td>{{ contest.contestName }}</td>
                    <td v-if="hasEntryFee">${{ contest.entryFee.toFixed(2) }}</td>
                    <td>{{ contest.entries.length }}</td>
                    <td class="text-right" v-if="viewOptions?.showActions">
                        <v-btn
                            small
                            depressed
                            color="default"
                            :disabled="isViewingContest(contest)"
                            @click="$emit('view-contest', contest.contestId)"
                        >
                            {{ isViewingContest(contest) ? 'VIEWING' : 'View Lineups' }}
                        </v-btn>
                        <v-btn
                            @click="$emit('delete-contest', contest.contestId)"
                            small
                            depressed
                            color="error"
                        >
                            Remove Contest
                        </v-btn>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td :colspan="footerColSpan" style="text-align: right;">
                        Total Entries:
                    </td>
                    <td>
                        {{ totalEntries }}
                    </td>
                    <td></td>
                </tr>
            </tfoot>
        </template>
    </v-simple-table>
</template>
  
<script>
    export default {
        name: "MyContestsView",
        props: {
            contests: {
                type: Array,
                required: true
            },
            viewContestId: {
                required: false
            },
            selectedContests: {
                type: Array
            },
            viewOptions: {
                default: () => {
                    return {
                        showCheckbox: false,
                        showActions: true
                    }
                }
            }
        },
        data: function () {
            return {
            };
        },
        computed: {
            selectedContestValues: {
                get() {
                    return this.selectedContests;
                },
                set(newValue) {
                    this.$emit('selected-contest-change', newValue)
                }
            },
            hasEntryFee() {
                return this.contests.some(c => c.entryFee);
            },
            totalEntries() {
                return this.contests.map(c => c.entries.length).reduce((a, b) => a+b);
            },
            footerColSpan() {
                var colSpan = 2;
                if (this.hasEntryFee)
                    colSpan = colSpan + 1;
                if (this.viewOptions?.showCheckbox)
                    colSpan = colSpan + 1;
                return colSpan;
            },
        },
        methods: {
            isViewingContest(contest) {
                return contest.contestId === this.viewContestId;
            }
        }
    };
</script>
<style scoped>
    .theme--light.v-data-table.v-data-table--dense thead th {
        background: #145DA0;
        padding: 0 8px;
    }
    td button {
        margin: 0 4px;
    }
</style>