<template>
    <v-dialog
        v-model="saveSettingsDialog"
        width="500"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn small secondary v-bind="attrs" v-on="on">
                Save Current Build Settings
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="text-h5 primary lighten-2">
                Save Build Settings
            </v-card-title>

            <v-card-text style="margin-top: 10px;">
                Save your current build settings so you can load them at a later time. This will allow you to continue to tinker with your settings, but then get back to a previously known state of settings if you wish. 
                This saves <strong>ALL</strong> of the following settings: 
                <ul>
                    <li>Exposures</li>
                    <li>Projection updates</li>
                    <li>Locked/unlocked players</li>
                    <li>Likes and dislikes</li>
                    <li>Rules</li>
                    <li>Stacks</li>
                    <li>Current slate settings</li>
                </ul>
                <br />
                <p>Saved and excluded lineups will <strong>NOT</strong> be saved with your settings.</p>
            </v-card-text>

            <v-card-text>
                <v-radio-group
                    v-model="saveType"
                    row
                >
                    <v-radio
                        label="Save as new settings"
                        value="new"
                    ></v-radio>
                    <v-radio
                        label="Save to existing settings"
                        value="existing"
                        :disabled="!hasExistingSavedSettings"
                    ></v-radio>
                </v-radio-group>
            </v-card-text>

            <v-card-text v-if="saveType === 'new'">
                <v-text-field
                    v-model="newSettingsName"
                    label="Settings Name"
                ></v-text-field>
            </v-card-text>

            <v-card-text v-if="saveType === 'existing'">
                <v-select
                    :items="existingNames"
                    v-model="existingSettingsName"
                    filled
                    label="Select Existing Settings"
                ></v-select>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    @click="save"
                >
                    Save
                </v-btn>
                <v-btn @click="cancel">
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
  name: "SaveBuildSettingsDialog",
  props: {
    savedSettings: {
        type: Array,
        required: true
    }
  },
  data: function () {
    return {
        saveType: 'new',
        saveSettingsDialog: false,
        newSettingsName: '',
        existingSettingsName: ''
    };
  },
  computed: {
    hasExistingSavedSettings: function() {
        return this.savedSettings && this.savedSettings.length > 0;
    },
    existingNames: function() {
        if (!this.hasExistingSavedSettings)
            return [];
        return this.savedSettings.map(s => s.settingsName);
    },
    settingsName: function() {
        if (this.saveType === 'new')
            return this.newSettingsName;
        return this.existingSettingsName;
    }
  },
  methods: {
    save: function() {
        this.$emit('set-saved-settings', {
            settingsName: this.settingsName,
            successCallback: () => this.cancel(),
            errorCallback: () => console.log('Error setting saved build settings!')
        });
    },
    cancel: function() {
        this.saveSettingsDialog = false;
        this.saveType = 'new';
        this.newSettingsName = '';
        this.existingSettingsName = '';
    }
  }
};
</script>