<template>
    <v-select :value="selectedTeam" 
                :items="sortedTeams" 
                label="Select Team"
                item-text="fullName"
                item-value="abbreviation"
                return-object
                filled
                @change="selected"
    > 
    </v-select>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('football');

export default {
    name: "TeamSelector",
    data: function () {
        return {
            selectedTeam: null
        };
    },
    props: {
        value: {
            type: Object
        },
        autoSelect: {
            type: Boolean,
            default: true
        }
    },
    mounted: function() {
        if (!this.selectedTeam && this.autoSelect) {
            this.selectedTeam = this.sortedTeams[0];
            this.$emit('input', this.selectedTeam);
        }      
    },
    computed: {
        sortedTeams() {
            var sorted = this.teams.map(t => t);
            sorted.sort((a,b) => (a.fullName > b.fullName) ? 1 : ((b.fullName > a.fullName) ? -1 : 0));
            return sorted;
        },
        ...mapState({
            teams: state => state.teams
        })
    },
    methods: {
        selected(newValue) {
            this.selectedTeam = newValue;
            this.$emit('input', this.selectedTeam);
            this.$emit('change');
        }
    }
};
</script>
<style scoped>
</style>