<template>
    <div class="c-container" v-if="!accessRequested">
        Sorry, you do not have access to this application. <a @click="requestAccess">Click here to request Beta access for this Lineup Optimizer.</a>
    </div>
    <div class="c-container" v-else>
        <div v-if="hasError">
            An error occurred while attempting to request Beta Access. <a @click="requestAccess">Click here to try again.</a> If the problem continues, please email us at <a href="mailto: support@sportsbytez.com">support@sportsbytez.com</a>.
        </div>
        <div v-else>
            You have requested Beta Access to this Lineup Optimizer. You will receive an email when you have been granted Beta Access.
        </div>
    </div>
</template>
  
<script>
    import SharedRestClient from '@/services/SharedRestClient';

    export default {
        name: "NoAccess",
        props: {
            sport: {
                type: String,
                required: true,
            }
        },
        data: function () {
            return {
                accessRequested: false,
                hasError: false,
            };
        },
        methods: {
            requestAccess() {
                SharedRestClient.user()
                    .requestBetaAccess(this.sport)
                    .then(() => this.accessRequested = true)
                    .catch(() => this.hasError = true);
            }
        }
    };
</script>
<style scoped>
div.c-container {
    margin: auto;
    width: 75%;
    margin-top: 20px;
    text-align: center;
}
</style>