import Axios from 'axios';

export default {
    contestEntries() {
        const token = localStorage.getItem('bearer_token');
        const headers = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        return {
            getContests: (provider, slate) => Axios.get(`/baseball/ContestEntry/contests/${provider}/${slate}`, headers),
            removeContest: (provider, slate, contest) => Axios.delete(`/baseball/ContestEntry/removeContest/${provider}/${slate}/${contest}`, headers),
            fillContests: (provider, slate, contestIds) => Axios.post(`/baseball/ContestEntry/fillContests/${provider}/${slate}`, contestIds, headers),
            exportEntries: (providerId, slateId) => Axios.post(`/baseball/ContestEntry/exportEntries/${providerId}/${slateId}`, null, { 
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}` 
                }
            }),
            uploadEntries: (provider, slate, file) => Axios.post(`/baseball/ContestEntry/uploadEntries/${provider}/${slate}`, file, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }})
        }
    },
    contestProvider() {
        const token = localStorage.getItem('bearer_token');
        const headers = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        return {
            getAll: () => Axios.get('/baseball/ContestProviders', headers)
        }
    },
    games() {
        const token = localStorage.getItem('bearer_token');
        const headers = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        return {
            get: (day) => Axios.get(`/baseball/Games/${new Date(day).toISOString().split('T')[0]}`, headers),
            getOdds: (slateId) => Axios.get(`/baseball/GameOdds/${slateId}`, headers)
        }
    },
    gameSlates() {
        const token = localStorage.getItem('bearer_token');
        const headers = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        return {
            get: (providerId, day) => Axios.get(`/baseball/GameSlate/${providerId}/${new Date(day).toISOString().split('T')[0]}`, headers),
            getCurrentValues: (providerId) => Axios.get(`/baseball/GameSlate/currentValues/${providerId}`, headers),
        }
    },
    lineupOptimizer() {
        const token = localStorage.getItem('bearer_token');
        const headers = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        return {
            getLineups: (providerId, slateId) => Axios.get(`/baseball/LineupOptimizer/GetLineups/${providerId}/${slateId}`, headers),
            build: (settings) => Axios.post('/baseball/LineupOptimizer', settings, headers),
            export: (providerId, slateId, lineups) => Axios.post(`/baseball/LineupOptimizer/ExportLineups/${providerId}/${slateId}`, lineups, { 
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}` 
                }
            }),
            getSettings: (providerId, slateId) => Axios.get(`/baseball/LineupOptimizer/settings?providerId=${providerId}&slateId=${slateId}`, headers),
            getSavedSettings: (providerId, slateId) => Axios.get(`/baseball/LineupOptimizer/savedSettings?providerId=${providerId}&slateId=${slateId}`, headers),
            deleteSavedSettings: (providerId, slateId, settingsName) => Axios.delete(`/baseball/LineupOptimizer/savedSettings?providerId=${providerId}&slateId=${slateId}&settingsName=${settingsName}`, headers),
            updateSettings: (settings) => Axios.put('/baseball/LineupOptimizer/settings', settings, headers),
            updateSavedSettings: (settingsName, settings) => Axios.put(`/baseball/LineupOptimizer/savedSettings?settingsName=${settingsName}`, settings, headers),
            saveLineup: (providerId, slateId, lineup) => Axios.post(`/baseball/LineupOptimizer/SaveLineup/${providerId}/${slateId}`, lineup, headers),
            removeSavedLineup: (providerId, slateId, lineup) => Axios.post(`/baseball/LineupOptimizer/RemoveSavedLineup/${providerId}/${slateId}`, lineup, headers),
            excludeLineup: (providerId, slateId, lineup) => Axios.post(`/baseball/LineupOptimizer/ExcludeLineup/${providerId}/${slateId}`, lineup, headers),
            removeExcludedLineup: (providerId, slateId, lineup) => Axios.post(`/baseball/LineupOptimizer/RemoveExcludedLineup/${providerId}/${slateId}`, lineup, headers),
            getStoredLineups: (providerId, slateId) => Axios.get(`/baseball/LineupOptimizer/storedLineups?providerId=${providerId}&slateId=${slateId}`, headers),
        }
    },
    projections() {
        const token = localStorage.getItem('bearer_token');
        const headers = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        return {
            get: (providerId, slateId) => Axios.get(`/baseball/PlayerProjections?providerId=${providerId}&slateId=${slateId}`, headers),
            getLastUpdated: (day, slateId) => Axios.get(`/baseball/PlayerProjections/lastUpdated/${new Date(day).toISOString().split('T')[0]}/${slateId}`, headers),
            update: (projection) => Axios.put(`/baseball/PlayerProjections/update`, projection, headers),
            updateAll: (projections) => Axios.put(`/baseball/PlayerProjections/updateAll`, projections, headers),
            trigger: (season, day) => Axios.post(`/baseball/PlayerProjections/triggerProjections/${season}/${new Date(day).toISOString().split('T')[0]}`, {}, headers)
        }
    },
    teams() {
        const token = localStorage.getItem('bearer_token');
        const headers = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        return {
            getAll: () => Axios.get('/baseball/Teams', headers),
        }
    },
}