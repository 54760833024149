<template>
    <StackBuilder :stacks="stacks"
        :projections="projections"
        :is-dirty="isDirty"
        @create-stack="createStack"
        @stack-changed="onStackChange"
        @delete-stack="deleteStack"
        @save="save" />
</template>

<script>
import StackBuilder from '@/components/shared/StacksBuilder/StacksBuilder.vue'

import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions, mapMutations } = createNamespacedHelpers('baseball');
import { v4 as uuidv4 } from 'uuid';

export default {
    name: "BaseballStacksBuilder",
    components: {
        StackBuilder
    },
    data: function () {
        return {
            isDirty: false
        };
    },
    computed: {
        ...mapState({
            stacks: state => state.settings.stacks,
            projections: state => state.projections.data
        })
    },
    methods: {
        createStack: function() {
            this.addStack({
                id: uuidv4(),
                stackWithSelection: null,
                stackWithInput: '',
                driverInput: '',
                stackType: 0,
                minExposure: 0,
                maxExposure: 100,
                minStacked: 1,
                maxStacked: 3,
                driverProjectionId: null,
                stackedProjectionIds: []
            });
            this.isDirty = true;
            this.$gtag.event('Create Stack');
        },
        onStackChange: function(stack) {
            this.isDirty = true;
            this.updateStack(stack);
            this.$gtag.event('Change Stack');
        },
        save: function() {
            this.saveBuildSettings();
            this.isDirty = false;
            this.$gtag.event('Save Stack');
        },
        deleteClick: function(stack) {
            this.deleteStack(stack);
            this.saveBuildSettings()
            this.$gtag.event('Delete Stack');
        },
        ...mapActions({
            saveBuildSettings: 'saveBuildSettings'
        }),
        ...mapMutations({
            addStack: 'ADD_STACK',
            updateStack: 'UPDATE_STACK',
            deleteStack: 'DELETE_STACK',
        })
    }
};
</script>
<style scoped>
.top-margin {
  margin-top: 20px;
}
.padding-10 {
    padding: 10px;
}
.mtb {
    margin-top: 6px;
    margin-bottom: 6px;
}
div.inline {
    display: inline-block;
    width: 300px;
}
div.sm {
    width: 100px;
}
div.action-buttons {
    float: right;
    width: 400px;
    text-align: right;
}
</style>