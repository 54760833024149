<template>
    <v-container>
        <div v-if="loading" class="m-auto" width="500" style="text-align: center; margin-top: 100px;">
            <span style="margin-right: 20px;">Loading user data...</span>
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>
        <div v-else-if="loadingLink" class="m-auto" width="500" style="text-align: center; margin-top: 100px;">
            <span style="margin-right: 20px;">Loading page...</span>
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>
        <v-card elevation="2" class="m-auto" max-width="500" v-else>
            <v-alert
                dense
                type="error"
                v-if="loadingLinkError"
            >
                There was an error loading your page link. Please try again.
            </v-alert>
            <v-list-item two-line>
                <v-list-item-content>
                    <v-list-item-title class="text-h5" style="text-align: center;">
                        Account Details
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-row>
                <v-col cols="4">
                    <strong>Email:</strong>
                </v-col>
                <v-col cols="8">
                    {{ email }}
                </v-col>
            </v-row>
            <v-row v-if="!hasSubscription">
                <v-col cols="4">
                    <strong>Subscription Tier:</strong>
                </v-col>
                <v-col cols="8">
                    Free (<RouterLink to="/plans">Upgrade Tier</RouterLink>)
                </v-col>
            </v-row>
            <v-row v-if="hasSubscription">
                <v-col cols="4">
                    <strong>Subscription Tier:</strong>
                </v-col>
                <v-col cols="8">
                    {{ tierName }} <br />
                    <span style="font-size: 14px;">{{ subscriptionInfo }}</span>
                </v-col>
                <v-col cols="12">
                    <a @click="customerPortal">Manage Subscription/Payment</a>
                </v-col>
                <v-col cols="12">
                    <a @click="changeSubscription">Update Subscription</a>
                </v-col>
                <v-col cols="12" v-if="!hasCanceled">
                    <a @click="cancelSubscription">Cancel Subscription</a>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
  
<script>
    import SharedRestClient from '@/services/SharedRestClient';

    export default {
        name: "AccountDetails",
        data: function () {
            return {
                loading: false,
                loadingLink: false,
                loadingLinkError: false,
                userData: null,
            };
        },
        mounted: function() {
            this.loading = true;
            SharedRestClient.user()
                .get(this.userId)
                .then((response) => {
                    this.userData = response.data
                    console.log(this.userData);
                })
                .finally(() => this.loading = false);
        },
        computed: {
            userId() {
                return this.$auth0.user['sub'];
            },
            email() {
                return this.$auth0.user['email'];
            },
            stripeUserId() {
                return this.$auth0.user['https://dfs.sportsbytez.com/stripeId'];
            },
            hasSubscription() {
                return this.userData?.hasSubscription;
            },
            hasCanceled() {
                return this.userData?.hasCanceled;
            },
            subscriptionInfo() {
                if (!this.hasSubscription)
                    return null;
                if (this.userData?.hasCanceled)
                    return `(Expires on ${this.subscriptionEndDate})`;
                return `(Renews on ${this.subscriptionEndDate})`;
            },
            subscriptionEndDate() {
                return new Date(this.userData.subscriptionEndDate).toLocaleDateString('en-US');
            },
            tierName() {
                return `${this.userData?.subscriptionTierName} Tier`;
            },
        },
        methods: {
            customerPortal() {
                this.loadingLink = true;
                this.loadingLinkError = false;
                SharedRestClient.subscriptions()
                    .manageSubscription()
                    .then((response) => window.open(response.data.url, "_self"))
                    .catch(() => {
                        this.loadingLinkError = true;
                        this.loadingLink = false;
                    });
            },
            changeSubscription() {
                this.loadingLink = true;
                this.loadingLinkError = false;
                SharedRestClient.subscriptions()
                    .updateSubscription()
                    .then((response) => window.open(response.data.url, "_self"))
                    .catch(() => {
                        this.loadingLinkError = true;
                        this.loadingLink = false;
                    });
            },
            cancelSubscription() {
                this.loadingLink = true;
                this.loadingLinkError = false;
                SharedRestClient.subscriptions()
                    .cancelSubscription()
                    .then((response) => window.open(response.data.url, "_self"))
                    .catch(() => {
                        this.loadingLinkError = true;
                        this.loadingLink = false;
                    });
            }
        }
    };
</script>

<style scoped>
.m-auto {
  margin: auto;
  margin-top: 20px;
}
div.v-card {
    padding: 20px;
}
</style>