<template>
    <v-card class="mx-auto padding-10 mtb" outlined>
        <div>
            <div class="action-buttons">
                <v-btn
                    class="ma-2"
                    color="error"
                    @click="$emit('delete-rule', rule)"
                >
                    <v-icon
                        left
                        dark
                    >
                        mdi-minus-circle
                    </v-icon>
                    Delete Rule
                </v-btn>
            </div>
            <div>
                {{ ruleDescription }}
            </div>
            <div class="mtb" v-if="rule.groupRuleGroupBy === 'team'">
                <v-chip v-for="team in rule.groupRuleTeams" :key="team">{{ team }}</v-chip>
            </div>
            <div class="mtb" v-if="rule.groupRuleGroupBy === 'game'">
                <v-chip v-for="gameId in rule.groupRuleGames" :key="gameId">{{ getGame(gameId)?.display }}</v-chip>
            </div>
            <div style="margin-top: 10px;">
                Of players that play the following position(s):
            </div>
            <div class="mtb">
                <v-chip v-for="pos in rule.groupRulePositions" :key="pos">{{ pos }}</v-chip>
            </div>
            <div style="margin-top: 10px;" v-if="hasRosterSpots">
                <div>
                    At the following roster spot(s):
                </div>
                <div class="mtb">
                    <v-chip v-for="spot in rule.groupRuleRosterSpots" :key="spot">{{ spot }}</v-chip>
                </div>
            </div>
            <div v-if="rule.groupRuleType === 'If'">
                <div>
                    Then use {{ secondaryRuleComparerDescription }} {{  rule.secondaryRuleAmount }} player<span v-if="rule.secondaryRuleAmount > 1">s</span> from the {{ rule.secondaryTeam.toLowerCase() }} team at position(s):
                </div>
                <div class="mtb">
                    <v-chip v-for="pos in rule.secondaryPositions" :key="pos">{{ pos }}</v-chip>
                </div>
                <div style="margin-top: 10px;" v-if="hasSecondaryRosterSpots">
                    <div>
                        At the following roster spot(s):
                    </div>
                    <div class="mtb">
                        <v-chip v-for="spot in rule.secondaryRosterSpots" :key="spot">{{ spot }}</v-chip>
                    </div>
                </div>
            </div>
        </div>
    </v-card>
</template>

<script>
export default {
    name: "RuleView",
    props: {
        rule: {
            type: Object,
            required: true
        },
        games: {
            type: Array,
            required: true
        }
    },
    data: function () {
        return {};
    },
    computed: {
        hasRosterSpots() {
            return this.rule.groupRuleRosterSpots && this.rule.groupRuleRosterSpots.length;
        },
        hasSecondaryRosterSpots() {
            return this.rule.secondaryRosterSpots && this.rule.secondaryRosterSpots.length;
        },
        ruleDescription() {
            return `${this.rule.groupRuleType} ${this.ruleComparerDescription} ${this.rule.groupRuleAmount} player(s) in lineups from ${this.rule.groupRuleForType.toLowerCase()} of the following ${this.rule.groupRuleGroupBy}(s):`;
        },
        ruleComparerDescription() {
            if (this.rule.groupRuleComparer === '=')
                return 'exactly';
            if (this.rule.groupRuleComparer === '<=')
                return 'no more than';
            return 'no less than';
        },
        secondaryRuleComparerDescription() {
            if (this.rule.secondaryRuleComparer === '=')
                return 'exactly';
            if (this.rule.secondaryRuleComparer === '<=')
                return 'no more than';
            return 'no less than';
        }
    },
    methods: {
        getGame(gameId) {
            return this.games.find(g => g.gameId === gameId);
        },
    }
};
</script>
<style scoped>
.top-margin {
  margin-top: 20px;
}
.padding-10 {
    padding: 10px;
}
.mtb {
    margin-top: 6px;
    margin-bottom: 6px;
}
div.inline {
    display: inline-block;
    width: 300px;
}
div.sm {
    width: 100px;
}
div.action-buttons {
    float: right;
    width: 400px;
    text-align: right;
}
span.v-chip {
    margin: 0 2px;
}
</style>