<template>
    <div>
        <v-row>
            <v-col cols="10">
                <v-btn
                    v-for="filter in positionFilters"
                    :key="filter"
                    class="ma-2"
                    small
                    :outlined="filter !== positionFilter"
                    color="blue"
                    @click="positionFilter = filter"
                >
                    {{ filter }}
                </v-btn>
            </v-col>
            <v-col cols="2">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="ma-2 btn-icon-action"
                            color="green"
                            v-bind="attrs"
                            v-on="on"
                            @click="resetExposures"
                            icon
                            small
                        >
                            <v-icon>
                                mdi-lock-reset
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Reset all exposures back to default values (0% minimum, 100% maximum)</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-data-table
            dense
            :headers="headers"
            :items="viewProjections"
            :items-per-page="100"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :footer-props="footerProps"
            class="elevation-1 top-margin"
        >
            <template v-slot:[`item.name`]="{ item }">
                <v-icon
                  title="Can no longer be modified, the game this player is participating in has started."
                  color="red"
                  style="font-size: 20px;"
                  v-if="item.hasGameStarted"
                >
                    mdi-lock
                </v-icon>
                <span v-if="!item.hasGameStarted && item.isLocked" class="lock">
                    <v-icon
                        v-if="item.isLocked"
                        label="Locked"
                        title="Locked"
                        size="medium">
                        mdi-lock
                    </v-icon>
                </span>
                <span v-if="item.player">{{ item.player.name }}</span>
                <span v-else>{{ item.team.mascot }}</span>
                <v-badge
                    v-if="item.injuryStatus"
                    :color="getInjuryStatusColor(item.injuryStatus)"
                    :content="item.injuryStatus.value"
                    :label="item.injuryStatus.displayName"
                    :title="item.injuryStatus.displayName"
                    inline
                ></v-badge>
            </template>
            <template v-slot:[`item.salary.rosterPosition`]="{ item }">
                {{ getPosition(item) }}
            </template>
            <template v-slot:[`item.opponent`]="{ item }">
                <span v-if="item.isHomeGame">vs. </span>
                <span v-else>@ </span>
                {{ item.opponent.abbreviation }}
            </template>
            <!--
            <template v-slot:[`item.projectedPoints.projectedOwnership`]="{ item }">
                {{ Math.round(item.projectedPoints.projectedOwnership) }}%
            </template>
            -->
            <template v-slot:[`item.projectedPoints.projectedPoints`]="{ item }">
                {{ item.customProjectedPoints.projectedPoints }}
            </template>
            <template v-slot:[`item.exposures`]="{ item }">
                {{ item.exposurePercent }}% ({{ item.exposures }})
            </template>
            <!--
            <template v-slot:[`item.leverage`]="{ item }">
                <span :class="item.leverage > 0 ? 'plus' : (item.leverage < 0 ? 'minus' : null)">{{ item.leverage }}%</span>
            </template>
            -->
            <template v-slot:[`item.minExposure`]="{ item }">
                <EditableValue
                    v-model="item.minExposure"
                    :disabled="!canEditExposure(item)"
                    @updated="save" />
            </template>
            <template v-slot:[`item.maxExposure`]="{ item }">
                <v-text-field
                    v-model="item.maxExposure"
                    class="centered-input maxexp"
                    :disabled="!canEditExposure(item)"
                    solo
                    @change="validateMaxExposureAndSave(item)"
                ></v-text-field>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import EditableValue from '@/components/EditableValue.vue';

import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('football');

export default {
    name: "BuildExposuresTable",
    components: {
        EditableValue
    },
    props: {
        lineups: {
            type: Array,
            require: true
        },
        searchFilter: {
            type: String
        }
    },
    data: function () {
        return {
            sortBy: 'exposures',
            sortDesc: true,
            positionFilter: 'ALL',
            headers: [
                {
                    text: "Player",
                    value: "name",
                    sortable: false,
                    divider: true
                },
                {
                    text: "Pos",
                    value: "salary.rosterPosition",
                    divider: true
                },
                {
                    text: "Status",
                    value: "rosterStatus",
                    sortable: false,
                    divider: true
                },
                {
                    text: "Salary",
                    value: "salary.salary",
                    divider: true
                },
                {
                    text: "Team",
                    value: "team.abbreviation",
                    divider: true
                },
                {
                    text: "Opp",
                    value: "opponent",
                    sortable: false,
                    divider: true
                },
                {
                    text: "Proj",
                    value: "customProjectedPoints.projectedPoints",
                    divider: true
                },
                {
                    text: "Value",
                    value: "value",
                    divider: true
                },
                // {
                //     text: "Own %",
                //     value: "projectedPoints.projectedOwnership",
                //     divider: true,
                //     width: "88px",
                // },
                {
                    text: "Exp",
                    value: "exposures",
                    divider: true,
                    width: "90px",
                },
                // {
                //     text: "Lev",
                //     value: "leverage",
                //     divider: true,
                //     width: "70px",
                // },
                {
                    text: "Min Exp",
                    value: "minExposure",
                    width: "100px",
                    divider: true
                },
                {
                    text: "Max Exp",
                    value: "maxExposure",
                    width: "100px"
                },
            ],
        };
    },
    mounted: function() {
        this.positionFilter = this.slate?.numberOfGames > 1 ? 'ALL' : (this.provider.abbreviation == 'DK' ? 'CPT' : 'MVP');
        this.$store.subscribe((mutation) => {
            // When slates change, set our projection filter to the default value depending on number of games of the selected slate
            if (mutation.type === 'football/UPDATE_SLATE' || mutation.type === 'football/UPDATE_SLATES') {
                if (this.slate?.numberOfGames === 1)
                    this.positionFilter = this.provider.abbreviation == 'DK' ? 'CPT' : 'MVP';
                else
                    this.positionFilter = 'ALL';
            }
        });
    },
    computed: {
        projections() {
            return this.storeProjections.map(p => {
                var exposures = this.lineups.filter(l => l.allSpots.some(a => a.id === p.id));
                var exposurePercent = Math.round(((exposures.length / this.settings.numberOfLineupsToBuild) * 100).toFixed(1));
                var leverage = Math.round(exposurePercent - p.projectedPoints.projectedOwnership);
                return {
                    ...p,
                    leverage: leverage,
                    exposures: exposures.length,
                    exposurePercent: exposurePercent
                };
            })
            .filter(p => p.projectedFantasyPoints > 0)
            .sort((a, b) => b.exposurePercent - a.exposurePercent);
        },
        viewProjections() {
            var viewable = [];
            if (!this.projections || this.projections.length === 0)
                return [];
            else if (this.positionFilter === 'ALL')
                viewable = [...this.projections];
            else if (this.positionFilter === 'DEF')
                viewable = this.projections.filter(p => !p.player);
            else
            {
                if (this.slate?.numberOfGames > 1)
                    viewable = this.projections.filter(p => p.player && p.player.position === this.positionFilter);
                else
                    viewable = this.projections.filter(p => p.salary.rosterPosition === this.positionFilter);
            }
            if (this.searchFilter)
                return viewable.filter(v => (v.player && v.player.name.toLowerCase().includes(this.searchFilter.toLowerCase())) || v.team.fullName.toLowerCase().includes(this.searchFilter.toLowerCase()));
            return viewable;
        },
        footerProps() {
            return {
                'items-per-page-options': [
                    10,
                    25,
                    50,
                    100,
                    -1
                ]
            };
        },
        positionFilters() {
            if (this.slate?.numberOfGames > 1)
                return [
                    'ALL',
                    'QB',
                    'RB',
                    'WR',
                    'TE',
                    'DEF'
                ];
            if (this.provider.abbreviation == "DK")
                return [
                    'CPT',
                    'FLEX'
                ];
            return [
                'MVP',
                'FLEX'
            ];
        },
        ...mapState({
            provider: state => state.providers.selected,
            slate: state => state.slates.selected,
            settings: state => state.settings,
            storeProjections: state => state.projections.data
        })
    },
    methods: {
        canEditExposure(projection) {
            if (projection.hasGameStarted)
                return false;
            return !projection.isLocked;
        },
        resetExposures() {
            this.projections.forEach(p => {
                p.minExposure = 0;
                p.maxExposure = 100;
            });
            this.save();
        },
        validateMaxExposureAndSave(projection) {
            if (projection.maxExposure > this.settings.maxExposure)
                projection.maxExposure = this.settings.maxExposure;
            this.save();
        },
        getPosition(projection) {
            if (this.slate?.numberOfGames > 1)
                return projection.salary.rosterPosition;
            return projection.player ? projection.player.position : 'DEF';
        },
        getInjuryStatusColor(status) {
            if (status.value === 'P')
                return '#43A047';
            if (status.value === 'Q')
                return '#1E88E5';
            if (status.value === 'D')
                return '#F57C00';
            return '#D32F2F';
        },
        save() {
            this.saveCustomProjections(this.projections);
        },
        ...mapActions([
            'saveCustomProjections'
        ])
    }
};
</script>
<style scoped>
div.row {
    margin-top: 6px;
    margin-bottom: 6px;
}
button.btn-icon-action {
    float: right;
}
.plus {
    color: green;
}
.minus {
    color: red;
}
span.lock {
    margin-right: 5px;
}
</style>
<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.78rem;
}
.v-data-table-header {
  background-color: #145DA0;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.theme--light.v-data-table .v-data-table-header th.sortable:hover, 
.theme--light.v-data-table .v-data-table-header th.sortable.active,
.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon,
.theme--light.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon {
    color: white;
    font-weight: bold;
}
.theme--light.v-input.v-text-field--solo input {
    color: white;
}
.v-text-field.v-text-field--solo .v-input__control {
    min-height: auto;
    padding: 3px;
}
.theme--light.v-input input {
    min-height: auto;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0px;
}
.v-text-field__details {
    display: none;
}
table .v-input {
    font-size: 0.8rem;
}
table .v-input__slot {
    margin-bottom: 0px;
}
.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: #145DA0;
}
.theme--light.v-text-field--solo.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #2E8BC0;
}
div.v-input.maxexp.v-input--is-disabled div.v-input__slot {
  background: darkslategray !important;
}
</style>