<template>
    <div class="c-container">
      Sorry, you do not have access to this page.
    </div>
</template>
  
<script>
    export default {
        name: "NoPageAccess",
        data: function () {
            return {
            };
        }
    };
</script>
<style scoped>
div.c-container {
    margin: auto;
    width: 75%;
    margin-top: 20px;
    text-align: center;
}
</style>