<template>
    <v-dialog
        ref="dialog"
        v-model="modal"
        width="290px">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateFormatted"
                label="Select Slate Day"
                readonly
                filled
                :disabled="disabled"
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="dateValue"
            @input="setCloseDate"
            scrollable
            :allowed-dates="allowedDates"
            :max="maxSlateDate"
            :min="minSlateDate"
        >
        </v-date-picker>
    </v-dialog>
</template>
<script>
export default {
    name: "DaySelector",
    data: function () {
        return {
            modal: false
        };
    },
    props: {
        selectedDay: {
            required: true
        },
        slateDays: {
            type: Array,
            required: true
        },
        backtesting: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        dateValue: {
            get: function() {
                return new Date(this.selectedDay).toISOString().split('T')[0];
            },
            set: function(newVal) {
                this.$gtag.event('change day', { 
                    'event_category': 'Interaction',
                    'event_label': 'Day Change',
                    'value': new Date(newVal).toISOString()
                });
                this.$emit('change', newVal);
            }
        },
        dateFormatted() {
            if (!this.dateValue) 
                return null;

            const [year, month, day] = this.dateValue.split('-')
            return `${month}/${day}/${year}`
        },
        maxSlateDate() {
            const maxDate = new Date(Math.max.apply(null, this.slateDays.map(x => new Date(x))));
            if (this.isValidDate(maxDate))
                return maxDate.toISOString().split('T')[0];
            return new Date().toISOString().split('T')[0];
        },
        minSlateDate() {
            const minDate = new Date(Math.min.apply(null, this.slateDays.map(x => new Date(x))));
            if (this.isValidDate(minDate))
                return minDate.toISOString().split('T')[0];
            return new Date().toISOString().split('T')[0];            
        }
    },
    methods: {
        isValidDate(d) {
            return d instanceof Date && !isNaN(d.getTime());
        },
        setCloseDate() {
            this.$refs.dialog.save(this.dateValue);
            this.modal = false;
        },
        allowedDates(val) {
            return this.slateDays.filter(x => new Date(x).toISOString().split('T')[0] === val).length > 0;
        }
    }
};
</script>
<style scoped>
</style>