<template>
    <div class="c-container">
      This optimizer is not currently available while out of season. Please check back on or after {{ dateAvailable.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        }) }} for availability.
    </div>
</template>
  
<script>
    export default {
        name: "OutOfSeason",
        props: {
            dateAvailable: {
                required: true
            }
        },
        data: function () {
            return {
            };
        }
    };
</script>
<style scoped>
div.c-container {
    margin: auto;
    width: 75%;
    margin-top: 20px;
    text-align: center;
}
</style>