import Vue from 'vue'
import RestClient from "@/services/FootballRestClient";

export default {
  namespaced: true,
  state: {
    userAuthToken: null,
    currentYear: 0,
    currentWeek: 0,
    selectedYear: new Date().getFullYear(),
    selectedWeek: 0,
    teams: [],
    lineups: {
      data: null,
      building: false,
      error: false
    },
    providers: {
      loaded: false,
      loading: false,
      selected: null,
      data: []
    },
    slates: {
      loaded: false,
      loading: false,
      selected: null,
      error: false,
      data: []
    },
    odds: {
      loading: false,
      data: []
    },
    projections: {
      loaded: false,
      loading: false,
      data: []
    },
    contests: {
      loaded: false,
      loading: false,
      data: []
    },
    savedSettings:[],
    settingsErrors:[],
    settings: {
      numberOfLineupsToBuild: 10,
      minimumProjectedPointsToInclude: 3,
      minimumUniquePlayers: 1,
      minimumSalary: 48500,
      maximumSalary: 50000,
      includePositionsInFlex: ['RB','WR','TE'],
      maximumPlayersFromSameTeam: 3,
      maxExposure: 100,
      stacks: [],
      rules: []
    },
    storedLineups: {
      savedLineups: [],
      excludedLineups: []
    }
  },
  getters: {
    getTeamByAbbreviation: (state) => (abbrev) => {
      return state.teams.find(t => t.abbreviation === abbrev);
    },
    builtLineups: (state) => {
      if (!state.lineups.data || state.lineups.data.length === 0 || !state.projections.loaded || state.projections.data.length === 0)
        return [];

      const getProjection = (x) => state.projections.data.find(p => p.id === x || p.salary.providerPlayerId === x);

      return state.lineups.data.map(s => {
        var l = {
            captainSpots: s.captains?.map(x => getProjection(x)) ?? [],
            defenses: s.defenses?.map(x => getProjection(x)) ?? [],
            flexSpots: s.flex?.map(x => getProjection(x)) ?? [],
            mvpSpots: s.mvps?.map(x => getProjection(x)) ?? [],
            quarterbacks: s.qbs?.map(x => getProjection(x)) ?? [],
            runningbacks: s.rbs?.map(x => getProjection(x)) ?? [],
            tightEnds: s.tes?.map(x => getProjection(x)) ?? [],
            wideReceivers: s.wrs?.map(x => getProjection(x)) ?? []
        }
        l.allSpots = l.captainSpots.concat(l.defenses, l.flexSpots, l.mvpSpots, l.quarterbacks, l.runningbacks, l.tightEnds, l.wideReceivers);
        return l;
      });
    },
  },
  mutations: {
    LOADING_PROVIDERS(state, payload) {
      state.providers = {
        ...state.providers,
        loading: payload
      };
    },
    UPDATE_PROVIDER(state, payload) {
      state.providers = {
        ...state.providers,
        selected: payload
      };
    },
    UPDATE_PROVIDERS(state, payload) {
      state.providers = {
        ...state.providers,
        data: payload,
        loaded: true,
        selected: payload[0]
      };
    },
    LOADING_SLATES(state, payload) {
      state.slates = {
        ...state.slates,
        loading: payload
      };
    },
    UPDATE_SLATE(state, payload) {
      state.slates = {
        ...state.slates,
        selected: payload
      };
    },
    UPDATE_SLATES(state, payload) {
      state.slates = {
        ...state.slates,
        data: payload,
        loaded: true,
        error: false,
        selected: payload.some(s => !s.hasSlateStarted) ? payload.find(s => !s.hasSlateStarted) : payload[0],
      };
    },
    UPDATE_SLATES_ERROR(state, payload) {
      state.slates = {
        ...state.slates,
        error: payload
      }
    },
    LOADING_PROJECTIONS(state, payload) {
      state.projections = {
        ...state.projections,
        loading: payload
      };
    },
    UPDATE_PROJECTIONS(state, payload) {
      state.projections = {
        ...state.projections,
        data: payload
      };
    },
    UPDATE_PROJECTIONS_LOADED(state, payload) {
      state.projections = {
        ...state.projections,
        loaded: payload
      };
    },
    LOADING_CONTESTS(state, payload) {
      state.contests = {
        ...state.contests,
        loading: payload
      };
    },
    UPDATE_CONTESTS(state, payload) {
      state.contests = {
        ...state.contests,
        data: payload
      };
    },
    UPDATE_CONTESTS_LOADED(state, payload) {
      state.contests = {
        ...state.contests,
        loaded: payload
      };
    },
    LOADING_ODDS(state, payload) {
      state.odds = {
        ...state.odds,
        loading: payload
      };
    },
    UPDATE_ODDS(state, payload) {
      state.odds = {
        ...state.odds,
        data: payload
      };
    },
    UPDATE_ODDS_LOADED(state, payload) {
      state.odds = {
        ...state.odds,
        loaded: payload
      };
    },
    UPDATE_CURRENT_YEAR(state, payload) {
      state.currentYear = payload;
    },
    UPDATE_CURRENT_WEEK(state, payload) {
      state.currentWeek = payload;
    },
    UPDATE_YEAR(state, payload) {
      state.selectedYear = payload;
    },
    UPDATE_WEEK(state, payload) {
      state.selectedWeek = payload;
    },
    UPDATE_SETTINGS_ERRORS(state, payload) {
        state.settingsErrors = [...payload];
      },
    UPDATE_SETTINGS(state, payload) {
      state.settings = {
        ...state.settings,
        ...payload
      };
    },
    UPDATE_SAVED_SETTINGS(state, payload) {
      state.savedSettings = payload;
    },
    UPDATE_STACKS(state, payload) {
      state.settings = {
        ...state.settings,
        stacks: payload
      };
    },
    UPDATE_STORED_LINEUPS(state, payload) {
      state.storedLineups = payload;
    },
    UPDATE_SAVED_LINEUPS(state, payload) {
      state.storedLineups = {
        ...state.storedLineups,
        savedLineups: payload
      };
    },
    UPDATE_EXCLUDED_LINEUPS(state, payload) {
      state.storedLineups = {
        ...state.storedLineups,
        excludedLineups: payload
      };
    },
    UPDATE_STACK(state, payload) {
      var stack = state.settings.stacks.find(s => s.id === payload.id);
      Object.assign(stack, payload);
    },
    DELETE_STACK(state, payload) {
      state.settings.stacks = [
        ...state.settings.stacks.filter(e => e.id !== payload.id)
      ];
    },
    ADD_STACK(state, payload) {
      if (!state.settings.stacks.find(s => s.id === payload.id))
        state.settings.stacks.unshift(payload);
    },
    ADD_RULE(state, payload) {
      if (!state.settings.rules.find(s => s.id === payload.id))
        state.settings.rules.unshift(payload);
    },
    DELETE_RULE(state, payload) {
      state.settings.rules = [
        ...state.settings.rules.filter(e => e.id !== payload.id)
      ];
    },
    BUILDING_LINEUPS(state, payload) {
      state.lineups = {
        ...state.lineups,
        building: payload
      };
    },
    ERROR_BUILDING_LINEUPS(state, payload) {
      state.lineups = {
        ...state.lineups,
        error: payload
      };
    },
    UPDATE_LINEUPS(state, payload) {
      state.lineups = {
        data: payload,
        building: state.lineups.building,
        error: state.lineups.error
      };
    },
    UPDATE_TEAMS(state, payload) {
      state.teams = payload;
    }
  },
  actions: {
    loadCurrentValues({commit, dispatch}) {
      RestClient.gameSlates()
        .getCurrentValues()
        .then((response) => {
          commit('UPDATE_CURRENT_YEAR', response.data.season);
          commit('UPDATE_CURRENT_WEEK', response.data.week);
          commit('UPDATE_YEAR', response.data.season);
          commit('UPDATE_WEEK', response.data.week);
          dispatch('loadSlates');
        })
        .catch(error => {
          throw new Error(`API ${error}`);
        });
    },
    loadTeams({commit}) {
      RestClient.teams()
        .getAll()
        .then((response) => {
          commit('UPDATE_TEAMS', response.data);
        })
        .catch(error => {
          throw new Error(`API ${error}`);
        });
    },
    loadProviders({state, commit, dispatch}) {
      if (!state.providers.loaded && !state.providers.loading) {
        commit('LOADING_PROVIDERS', true);
        RestClient.contestProvider()
          .getAll()
          .then((response) => {
            commit('UPDATE_PROVIDERS', response.data);
            dispatch('loadSlates');
          })
          .catch(error => {
            throw new Error(`API ${error}`);
          })
          .finally(() => commit('LOADING_PROVIDERS', false));
      }
    },
    loadSlates({state, commit, dispatch}) {
      // slates can be reloaded, so only look at .loading here
      if (!state.slates.loading &&
          state.selectedWeek &&
          state.selectedYear &&
          state.providers.selected) {

        commit('LOADING_SLATES', true);
        RestClient.gameSlates()
          .get(state.selectedYear, state.selectedWeek, state.providers.selected.id)
          .then((response) => {
            commit('UPDATE_SLATES', response.data);
            dispatch('reload');
          })
          .catch(() => {
            commit('UPDATE_SLATES_ERROR', true);
          })
          .finally(() => commit('LOADING_SLATES', false));
      }
    },
    loadProjections({state, commit}) {
      if (!state.projections.loading && state.slates.selected) {
        commit('LOADING_PROJECTIONS', true);
        RestClient.projections()
          .get(state.providers.selected.id, state.slates.selected.id)
          .then((response) => {
            commit('UPDATE_PROJECTIONS', response.data);
            commit('UPDATE_PROJECTIONS_LOADED', true);
          })
          .catch(error => {
            throw new Error(`API ${error}`);
          })
          .finally(() => commit('LOADING_PROJECTIONS', false));
      }
    },
    loadContests({state, commit}) {
      if (!state.contests.loading && state.slates.selected) {
        commit('LOADING_CONTESTS', true);
        RestClient.contestEntries()
          .getContests(state.providers.selected.id, state.slates.selected.id)
          .then((response) => {
            commit('UPDATE_CONTESTS', response.data);
            commit('UPDATE_CONTESTS_LOADED', true);
          })
          .catch(error => {
            throw new Error(`API ${error}`);
          })
          .finally(() => commit('LOADING_CONTESTS', false));
      }
    },
    loadOdds({state, commit}) {
      if (!state.odds.loading && state.slates.selected) {
        commit('LOADING_ODDS', true);
        RestClient.games()
          .getOdds(state.slates.selected.id)
          .then((response) => {
            commit('UPDATE_ODDS', response.data);
            commit('UPDATE_ODDS_LOADED', true);
          })
          .catch(error => {
            throw new Error(`API ${error}`);
          })
          .finally(() => commit('LOADING_ODDS', false));
      }
    },
    loadBuildSettings({state, commit}) {
      if (state.slates.selected) {
        RestClient.lineupOptimizer()
          .getSettings(state.providers.selected.id, state.slates.selected.id)
          .then((response) => {
            commit('UPDATE_SETTINGS', response.data);
          })
          .catch(error => {
            throw new Error(`API ${error}`);
          });
      }
    },
    loadSavedBuildSettings({state, commit}) {
      if (state.slates.selected) {
        RestClient.lineupOptimizer()
          .getSavedSettings(state.providers.selected.id, state.slates.selected.id)
          .then((response) => {
            commit('UPDATE_SAVED_SETTINGS', response.data);
          })
          .catch(error => {
            throw new Error(`API ${error}`);
          });
      }
    },
    loadStoredLineups({state, commit}) {
      if (state.slates.selected) {
        RestClient.lineupOptimizer()
          .getStoredLineups(state.providers.selected.id, state.slates.selected.id)
          .then((response) => {
            commit('UPDATE_STORED_LINEUPS', response.data);
          })
          .catch(error => {
            throw new Error(`API ${error}`);
          });
      }
    },
    changeProvider({commit, dispatch}, provider) {
      commit('UPDATE_PROVIDER', provider);
      commit('UPDATE_PROJECTIONS', []);
      commit('UPDATE_CONTESTS', []);
      commit('UPDATE_PROJECTIONS_LOADED', false);
      commit('UPDATE_ODDS', []);
      commit('UPDATE_ODDS_LOADED', false);
      commit('UPDATE_LINEUPS', []);
      commit('UPDATE_SAVED_SETTINGS', []);
      commit('UPDATE_STORED_LINEUPS', {
        savedLineups: [],
        excludedLineups: []
      });
      dispatch('loadSlates');
    },
    changeSlate({commit, dispatch}, slate) {
      commit('UPDATE_SLATE', slate);
      commit('UPDATE_PROJECTIONS', []);
      commit('UPDATE_CONTESTS', []);
      commit('UPDATE_PROJECTIONS_LOADED', false);
      commit('UPDATE_ODDS', []);
      commit('UPDATE_ODDS_LOADED', false);
      commit('UPDATE_LINEUPS', []);
      commit('UPDATE_SAVED_SETTINGS', []);
      commit('UPDATE_STORED_LINEUPS', {
        savedLineups: [],
        excludedLineups: []
      });
      dispatch('reload');
    },
    changeYear({commit, dispatch}, year) {
      commit('UPDATE_YEAR', year);
      commit('UPDATE_PROJECTIONS', []);
      commit('UPDATE_CONTESTS', []);
      commit('UPDATE_PROJECTIONS_LOADED', false);
      commit('UPDATE_ODDS', []);
      commit('UPDATE_ODDS_LOADED', false);
      commit('UPDATE_LINEUPS', []);
      commit('UPDATE_SAVED_SETTINGS', []);
      commit('UPDATE_STORED_LINEUPS', {
        savedLineups: [],
        excludedLineups: []
      });
      dispatch('loadSlates');
    },
    changeWeek({commit, dispatch}, week) {
      commit('UPDATE_WEEK', week);
      commit('UPDATE_PROJECTIONS', []);
      commit('UPDATE_CONTESTS', []);
      commit('UPDATE_PROJECTIONS_LOADED', false);
      commit('UPDATE_ODDS', []);
      commit('UPDATE_ODDS_LOADED', false);
      commit('UPDATE_LINEUPS', []);
      commit('UPDATE_SAVED_SETTINGS', []);
      commit('UPDATE_STORED_LINEUPS', {
        savedLineups: [],
        excludedLineups: []
      });
      dispatch('loadSlates');
    },
    reload({dispatch}) {
      dispatch('loadProjections');
      dispatch('loadOdds');
      dispatch('loadBuildSettings');
      dispatch('loadContests');
      dispatch('getLineups');
      dispatch('loadSavedBuildSettings');
      dispatch('loadStoredLineups');
    },
    // action to save custom projections
    saveCustomProjections({state, commit}, projections) {
      RestClient.projections()
            .updateAll({
                ProviderId: state.providers.selected.id,
                SlateId: state.slates.selected.id,
                Projections: projections.map(p => {
                  return {
                    Id: p.id,
                    IsLocked: p.isLocked,
                    MinExposure: p.minExposure,
                    MaxExposure: p.maxExposure,
                    Liked: p.liked,
                    Disliked: p.disliked,
                    ProjectedPoints: p.projectedPoints.projectedPoints,
                    CustomProjectedPoints: p.customProjectedPoints.projectedPoints
                  }
                })
            })
            .then(() => commit('UPDATE_PROJECTIONS', projections));
    },
    updateBuildSettingsErrors({commit}, payload) {
        commit('UPDATE_SETTINGS_ERRORS', payload);
    },
    // action to save build settings
    saveBuildSettings({state, commit}, payload) {
      const settingsToUpdate = payload?.settings ?? state.settings;
      RestClient.lineupOptimizer()
            .updateSettings({
                ProviderId: state.providers.selected.id,
                SlateId: state.slates.selected.id,
                stacks: state.settings.stacks,
                rules: state.settings.rules,
                ...settingsToUpdate
            })
            .then(() => {
              commit('UPDATE_SETTINGS', settingsToUpdate);
              if (payload && payload.callback)
                payload.callback();
            });
    },
    // action to set current settings as saved build settings by name
    setSavedBuildSettings({state, dispatch}, payload) {
      RestClient.lineupOptimizer()
            .updateSavedSettings(payload.settingsName, {
                ProviderId: state.providers.selected.id,
                SlateId: state.slates.selected.id,
                stacks: state.settings.stacks,
                rules: state.settings.rules,
                ...state.settings
            })
            .then(() => {
              dispatch('loadSavedBuildSettings');
              payload.successCallback();
            })
            .catch(() => payload.errorCallback());
    },
    // action to build lineups
    buildLineups({state, commit, dispatch}) {
      commit('UPDATE_LINEUPS', []);
      commit('ERROR_BUILDING_LINEUPS', false);
      commit('BUILDING_LINEUPS', true);

      Vue.nextTick(() => {
        RestClient.lineupOptimizer()
          .build({
            ...state.settings,
            providerId: state.providers.selected.id,
            slateId: state.slates.selected.id
          })
          .then(() => {
            // wait 1 second and start getting lineups
            setTimeout(() => dispatch('getLineups'), 1000);
          })
          .catch(() => {
            commit('BUILDING_LINEUPS', false);
            commit('ERROR_BUILDING_LINEUPS', true);
          });
      });
    },
    getLineups({state, commit, dispatch}) {
      return RestClient.lineupOptimizer()
          .getLineups(state.providers.selected.id, state.slates.selected.id)
          .then((response) => {
            commit('UPDATE_LINEUPS', response.data.lineups);

            // If completed building lineups, mark as done.
            // Otherwise, retrieve again in 1 second
            if (response.data.isCompleted)
              commit('BUILDING_LINEUPS', false);
            else if (response.data.hasErrors) {
              commit('BUILDING_LINEUPS', false);
              commit('ERROR_BUILDING_LINEUPS', true);
            }
            else
              setTimeout(() => dispatch('getLineups'), 1000);
          })
          .catch(() => {
            commit('BUILDING_LINEUPS', false);
            commit('ERROR_BUILDING_LINEUPS', true);
          });
    },
    saveLineup({state, commit}, payload) {
      return RestClient.lineupOptimizer()
          .saveLineup(payload.providerId, payload.slateId, payload.lineup)
          .then(() => {
            commit('UPDATE_SAVED_LINEUPS', [
              ...state.storedLineups.savedLineups,
              {
                captains: payload.lineup.Captains,
                defenses: payload.lineup.Defenses,
                flex: payload.lineup.Flex,
                mvps: payload.lineup.Mvps,
                qbs: payload.lineup.Qbs,
                rbs: payload.lineup.Rbs,
                tes: payload.lineup.Tes,
                wrs: payload.lineup.Wrs
              }
            ]);
          });
    },
    removeSavedLineup({state, commit}, payload) {
      return RestClient.lineupOptimizer()
          .removeSavedLineup(payload.providerId, payload.slateId, payload.lineup)
          .then(() => {
            const lineups = state.storedLineups.savedLineups.filter(l => {
              return !l.captains.every(e => payload.lineup.Captains.includes(e)) ||
                !l.defenses.every(e => payload.lineup.Defenses.includes(e)) ||
                !l.flex.every(e => payload.lineup.Flex.includes(e)) ||
                !l.mvps.every(e => payload.lineup.Mvps.includes(e)) ||
                !l.qbs.every(e => payload.lineup.Qbs.includes(e)) ||
                !l.rbs.every(e => payload.lineup.Rbs.includes(e)) ||
                !l.tes.every(e => payload.lineup.Tes.includes(e)) ||
                !l.wrs.every(e => payload.lineup.Wrs.includes(e));
            });
            commit('UPDATE_SAVED_LINEUPS', lineups);
          });
    },
    excludeLineup({state, commit}, payload) {
      return RestClient.lineupOptimizer()
          .excludeLineup(payload.providerId, payload.slateId, payload.lineup)
          .then(() => {
            commit('UPDATE_EXCLUDED_LINEUPS', [
              ...state.storedLineups.excludedLineups,
              {
                captains: payload.lineup.Captains,
                defenses: payload.lineup.Defenses,
                flex: payload.lineup.Flex,
                mvps: payload.lineup.Mvps,
                qbs: payload.lineup.Qbs,
                rbs: payload.lineup.Rbs,
                tes: payload.lineup.Tes,
                wrs: payload.lineup.Wrs
              }
            ]);
          });
    },
    removeExcludedLineup({state, commit}, payload) {
      return RestClient.lineupOptimizer()
          .removeExcludedLineup(payload.providerId, payload.slateId, payload.lineup)
          .then(() => {
            const lineups = state.storedLineups.excludedLineups.filter(l => {
              return !l.captains.every(e => payload.lineup.Captains.includes(e)) ||
                !l.defenses.every(e => payload.lineup.Defenses.includes(e)) ||
                !l.flex.every(e => payload.lineup.Flex.includes(e)) ||
                !l.mvps.every(e => payload.lineup.Mvps.includes(e)) ||
                !l.qbs.every(e => payload.lineup.Qbs.includes(e)) ||
                !l.rbs.every(e => payload.lineup.Rbs.includes(e)) ||
                !l.tes.every(e => payload.lineup.Tes.includes(e)) ||
                !l.wrs.every(e => payload.lineup.Wrs.includes(e));
            });
            commit('UPDATE_EXCLUDED_LINEUPS', lineups);
          });
    },
    // set the given settings as the current build settings
    setSettingsAsCurrent({commit}, payload) {
      commit('UPDATE_SETTINGS', payload)
    }
  }
}
