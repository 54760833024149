<template>
    <div>
      <v-simple-table dark>
        <template v-slot:default>
          <thead>
            <tr>
                <th style="width: 104px"></th>
                <th class="text-left">Player</th>
                <th class="text-center">Position</th>
                <th class="text-left">Status</th>
                <th class="text-center">Injury</th>
                <th class="text-center" v-if="showExtraColumns">Snap%</th>
                <th class="text-center" v-if="showExtraColumns">Pts</th>
                <th class="text-center">Depth</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="player in ordered" :key="player.playerId">
                <td>
                    <v-icon
                        v-if="player.isOnActiveRoster && player.positionDepth > 1"
                        @click="moveUpInDepth(player)"
                        title="Move Up in Depth Chart"
                    >
                        mdi-plus-circle
                    </v-icon>
                    <v-icon
                        v-if="player.isOnActiveRoster"
                        @click="moveDownInDepth(player)"
                        title="Move Down in Depth Chart"
                    >
                        mdi-minus-circle
                    </v-icon>
                    <v-icon
                        @click="openStatusDialog(player)"
                        title="Edit Player Roster Status"
                    >
                        mdi-pencil-circle
                    </v-icon>
                </td>
                <td>{{ player.playerName }}</td>
                <td class="text-center">{{ player.position }}</td>
                <td>{{ player.rosterStatus }}</td>
                <td class="text-center">{{ player.injuryStatus?.displayName.charAt(0) }}</td>
                <td class="text-center" v-if="showExtraColumns">{{ Math.round(player.snapPercentage * 100) }}%</td>
                <td class="text-center" v-if="showExtraColumns">{{ player.fantasyPoints }}</td>
                <td class="text-center">{{ player.positionDepth }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

        <v-dialog
            v-model="updateStatusDialog"
            width="500"
            >
            <v-card v-if="updateStatusPlayer">
                <v-card-title class="text-h5 grey lighten-2">
                    Update Status for {{ updateStatusPlayer.playerName }}
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-checkbox
                                    v-model="updateStatusPlayer.isOnActiveRoster"
                                    label="Is On Active Roster"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="12">
                                <v-combobox
                                    v-model="updateStatusPlayer.injuryStatus"
                                    :items="injuryOptions"
                                    item-text="displayName"
                                    item-value="value"
                                    label="Injury Status"
                                    clearable
                                    return-object
                                ></v-combobox>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    label="Roster Depth"
                                    v-model="updateStatusPlayer.positionDepth"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="postUpdateStatus(updateStatusPlayer)"
                    >
                        Save
                    </v-btn>
                    <v-btn
                        color="secondary"
                        @click="updateStatusDialog = null"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
  </template>
  
  <script>
  import RestClient from "@/services/FootballRestClient";

  import { createNamespacedHelpers } from 'vuex';
  const { mapState } = createNamespacedHelpers('football');

  export default {
    name: "RosterPositionView",
    data: () => ({
        updateStatusDialog: false,
        updateStatusPlayer: null,
        injuryOptions: [
            { value: 'P', displayName: 'Probable' },
            { value: 'Q', displayName: 'Questionable' },
            { value: 'D', displayName: 'Doubtful' },
            { value: 'O', displayName: 'Out' },
        ]
    }),
    props: {
        season: {
            type: Number,
            required: true
        },
        week: {
            type: Number,
            required: true
        },
        team: {
            type: Object,
            required: true
        },
        roster: {
            type: Array,
            required: true,
        },
    },
    computed: {
        showExtraColumns() {
            return this.season < this.currentSeason || (this.season == this.currentSeason && this.week < this.currentWeek);
        },
        maxDepth() {
            return Math.max(...this.roster.filter(r => r.positionDepth).map(r => r.positionDepth));
        },
        ordered() {
            var sorted = this.roster.map(r => r);
            sorted.sort((a,b) => (!a.positionDepth || a.positionDepth > b.positionDepth) ? 1 : ((b.positionDepth > a.positionDepth) ? -1 : 0));
            return sorted;
        },
        ...mapState({
            currentSeason: state => state.currentYear,
            currentWeek: state => state.currentWeek
        })
    },
    methods: {
        openStatusDialog(player) {
            this.updateStatusPlayer = player;
            this.updateStatusDialog = true;
        },
        moveUpInDepth(player) {
            RestClient.teams()
                .changeDepth(this.season, this.week, this.team.abbreviation, player.playerId, player.positionDepth - 1, player.position)
                .then(() => {
                    player.positionDepth = player.positionDepth - 1;
                });
        },
        moveDownInDepth(player) {
            RestClient.teams()
                .changeDepth(this.season, this.week, this.team.abbreviation, player.playerId, player.positionDepth + 1, player.position)
                .then(() => {
                    player.positionDepth = player.positionDepth + 1;
                });
        },
        postUpdateStatus(player) {
            RestClient.teams()
                .updateStatus(this.season, this.week, this.team.abbreviation, player.playerId, Number(player.positionDepth), player.position, player.injuryStatus?.value, player.isOnActiveRoster)
                .then(() => {
                    this.updateStatusDialog = false;
                    this.updateStatusPlayer = null;
                });
        }
    }
  };
  </script>
  <style scoped>
    .theme--dark.v-data-table {
        background-color: #0c2d48;
    }
  </style>