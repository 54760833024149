import Vue from 'vue'
import App from './App.vue'
import VueGtag from 'vue-gtag'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Axios from 'axios';
import { Auth0Plugin, useAuth0 } from './auth0-plugin';

Vue.config.productionTip = false

//console.log(process.env);

Axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
Axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
Axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

// Auth0 Authentication Plugin Setup
const auth0Options = {
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    redirectUri: process.env.VUE_APP_AUTH0_CALLBACK_URL,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    onRedirectCallback: (appState) => {
      router.push(
        appState && appState.targetPath
          ? appState.targetPath
          : window.location.pathname
      );
    }
};
Vue.use(Auth0Plugin, auth0Options);

// Setup axios interceptor to catch 401 and re-issue access tokens
Axios.interceptors.response.use(
    response => response,
    async error => {
        const auth0 = useAuth0(auth0Options);
        const originalRequest = error.config;

        if (error.response.status === 401) {
            try {
                // Try to get a new access token
                const newAccessToken = await auth0.getAccessTokenSilently();

                localStorage.setItem('bearer_token', newAccessToken);
                
                // Retry the original request
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                return Axios(originalRequest);
            } catch (authError) {
                // If we fail to refresh the token, log the user out or redirect to the login page
                await auth0.loginWithRedirect({
                    appState: {
                        targetPath: "/",
                    },
                });
                return Promise.reject(authError);
            }
        }

        return Promise.reject(error);
    }
);

// Setup Google Analytics in production
Vue.use(VueGtag, {
    config: {
        id: process.env.VUE_APP_GA_TRACKING,
        params: {
            send_page_view: false
        }
    }
}, router)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
