<template>
    <div style="margin-top: 10px;">
        <v-row v-if="exportable">
            <v-col cols="12">
                <v-btn small @click="exportLineups">
                    Export Saved Lineups
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3" v-for="(lineup, idx) in lineups" :key="idx"> 
                <LineupView :lineup="lineup" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import RestClient from "@/services/FootballRestClient";
import fileDownload from 'js-file-download';
import LineupView from "@/components/football/LineupView.vue";

import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('football');

export default {
    name: "StoredLineups",
    components: {
        LineupView
    },
    props: {
        storedLineups: {
            type: Array,
            required: true
        },
        exportable: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
        };
    },
    computed: {
        lineups() {
            return this.storedLineups.map(s => {
                return {
                    captainSpots: s.captains?.map(x => this.getProjection(x)) ?? [],
                    defenses: s.defenses?.map(x => this.getProjection(x)) ?? [],
                    flexSpots: s.flex?.map(x => this.getProjection(x)) ?? [],
                    mvpSpots: s.mvps?.map(x => this.getProjection(x)) ?? [],
                    quarterbacks: s.qbs?.map(x => this.getProjection(x)) ?? [],
                    runningbacks: s.rbs?.map(x => this.getProjection(x)) ?? [],
                    tightEnds: s.tes?.map(x => this.getProjection(x)) ?? [],
                    wideReceivers: s.wrs?.map(x => this.getProjection(x)) ?? []
                }
            });
        },
        ...mapState({
            projections: state => state.projections.data,
            slate: state => state.slates.selected,
            provider: state => state.providers.selected
        })
    },
    methods: {
        getProjection(projectionId) {
            return this.projections.find(p => p.id === projectionId || p.salary.providerPlayerId === projectionId);
        },
        exportLineups() {
            RestClient.lineupOptimizer()
                .export(this.provider.id, this.slate.id, this.storedLineups)
                .then(res => {
                    fileDownload(res.data, `${this.slate.name.replace(' ', '').replace('@', '-')} lineups.csv`);
                });
        },
    },
};
</script>