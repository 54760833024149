import Axios from 'axios';

export default {
    subscriptions() {
        const token = localStorage.getItem('bearer_token');
        const headers = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        return {
            createSubscription: (data) => Axios.post(`/subscription/create`, data, headers),
            manageSubscription: () => Axios.post(`/subscription/manage`, null, headers),
            updateSubscription: () => Axios.post(`/subscription/update`, null, headers),
            cancelSubscription: () => Axios.post(`/subscription/cancel`, null, headers),
        }
    },
    user() {
        const token = localStorage.getItem('bearer_token');
        const headers = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        return {
            get: (userId) => Axios.get(`/user/${userId}`, headers),
            requestBetaAccess: (sport) => Axios.post(`/user/requestBetaAccess/${sport}`, null, headers),
            sendFeedback: (feedback) => Axios.post(`/user/sendFeedback`, feedback, headers),
        }
    }
}