<template>
    <div>
      <v-simple-table dark>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Pos</th>
              <th class="text-left">Name</th>
              <th class="text-left">Team</th>
              <th class="text-left">Opp</th>
              <th class="text-left">Salary</th>
              <th class="text-left">Own</th>
              <th class="text-left">Proj</th>
              <th class="text-left" v-if="backtesting">Pts</th>
            </tr>
          </thead>
          <tbody>
            <LineupViewRow v-for="plr in lineup.mvpSpots" :key="getPlayerName(plr)" :player="plr" position="MVP" :backtesting="backtesting" />
            <LineupViewRow v-for="plr in lineup.captainSpots" :key="getPlayerName(plr)" :player="plr" position="CPT" :backtesting="backtesting" />
            <LineupViewRow v-for="plr in lineup.stars" :key="getPlayerName(plr)" :player="plr" position="STAR" :backtesting="backtesting" />
            <LineupViewRow v-for="plr in lineup.pitchers" :key="getPlayerName(plr)" :player="plr" position="P" :backtesting="backtesting" />
            <LineupViewRow v-for="plr in lineup.catchersOr1B" :key="getPlayerName(plr)" :player="plr" position="C/1B" :backtesting="backtesting" />
            <LineupViewRow v-for="plr in lineup.catchers" :key="getPlayerName(plr)" :player="plr" position="C" :backtesting="backtesting" />
            <LineupViewRow v-for="plr in lineup.firstBasemen" :key="getPlayerName(plr)" :player="plr" position="1B" :backtesting="backtesting" />
            <LineupViewRow v-for="plr in lineup.secondBasemen" :key="getPlayerName(plr)" :player="plr" position="2B" :backtesting="backtesting" />
            <LineupViewRow v-for="plr in lineup.thirdBasemen" :key="getPlayerName(plr)" :player="plr" position="3B" :backtesting="backtesting" />
            <LineupViewRow v-for="plr in lineup.shortstops" :key="getPlayerName(plr)" :player="plr" position="SS" :backtesting="backtesting" />
            <LineupViewRow v-for="plr in lineup.outfielders" :key="getPlayerName(plr)" :player="plr" position="OF" :backtesting="backtesting" />
            <LineupViewRow v-for="plr in lineup.utils" :key="getPlayerName(plr)" :player="plr" position="UTIL" :backtesting="backtesting" />
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2" v-if="!backtesting">
                <v-icon title="Saved Lineup" v-if="isSaved" color="green lighten-1">
                    mdi-table-check
                </v-icon>
                <v-icon @click="removeSavedLineup" title="Remove Saved Lineup" v-if="isSaved" color="red lighten-1">
                    mdi-close-circle
                </v-icon>
                <v-icon @click="removeExcludedLineup" title="Remove Excluded Lineup" v-if="isExcluded" color="red lighten-1">
                    mdi-close-circle
                </v-icon>
                <v-icon @click="saveLineup" title="Save Lineup" v-if="allowSaveRemoveLineup && !isSaved && !isExcluded">
                    mdi-table-plus
                </v-icon>
                <!-- Hiding this feature until the following issue is resolved: https://github.com/natedahl32/SportsBytezFantasy/issues/6 
                <v-icon @click="excludeLineup" title="Exclude Lineup" v-if="allowSaveRemoveLineup && !isExcluded && !isSaved">
                    mdi-table-remove
                </v-icon>
                -->
              </td>
              <td colspan="2" v-else>
                Pt Diff: {{ (totalActualScored - totalProjected).toFixed(2) }}
              </td>
              <td colspan="2" class="text-right">Totals</td>
              <td>
                {{ totalSalary }}
              </td>
              <td>
                {{ Math.round(totalOwnership) }}%
              </td>
              <td>
                {{ totalProjected.toFixed(2) }}
              </td>
              <td v-if="backtesting">
                {{ totalActualScored.toFixed(2) }}
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </div>
  </template>
  
  <script>
  import LineupViewRow from './LineupViewRow.vue';
  
  import { createNamespacedHelpers } from 'vuex';
  const { mapState, mapActions } = createNamespacedHelpers('baseball');
  
  export default {
    name: "LineupView",
    components: {
      LineupViewRow
    },
    data: () => ({
    }),
    props: {
      lineup: {
        type: Object,
        required: true,
      },
      allowSaveRemoveLineup: {
        type: Boolean,
        default: true,
      },
      backtesting: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      isSaved() {
        // Is this lineup already saved in settings?
        return this.storedLineups.savedLineups.some(l => {
          return l.captains?.every(e => this.lineup.captainSpots.some(s => s.id === e)) &&
            l.stars.every(e => this.lineup.stars.some(s => s.id === e)) &&
            l.utils.every(e => this.lineup.utils.some(s => s.id === e)) &&
            l.mvps.every(e => this.lineup.mvpSpots.some(s => s.id === e)) &&
            l.ps.every(e => this.lineup.pitchers.some(s => s.id === e)) &&
            l.csOr1B.every(e => this.lineup.catchersOr1B.some(s => s.id === e)) &&
            l.cs.every(e => this.lineup.catchers.some(s => s.id === e)) &&
            l.firsts.every(e => this.lineup.firstBasemen.some(s => s.id === e)) &&
            l.seconds.every(e => this.lineup.secondBasemen.some(s => s.id === e)) &&
            l.thirds.every(e => this.lineup.thirdBasemen.some(s => s.id === e)) &&
            l.sSs.every(e => this.lineup.shortstops.some(s => s.id === e)) &&
            l.oFs.every(e => this.lineup.outfielders.some(s => s.id === e));
        });
      },
      isExcluded() {
        // Is this lineup already excluded in settings?
        return this.storedLineups.excludedLineups.some(l => {
          return l.captains.every(e => this.lineup.captainSpots.some(s => s.id === e)) &&
            l.stars.every(e => this.lineup.stars.some(s => s.id === e)) &&
            l.utils.every(e => this.lineup.utils.some(s => s.id === e)) &&
            l.mvps.every(e => this.lineup.mvpSpots.some(s => s.id === e)) &&
            l.ps.every(e => this.lineup.pitchers.some(s => s.id === e)) &&
            l.csOr1B.every(e => this.lineup.catchersOr1B.some(s => s.id === e)) &&
            l.cs.every(e => this.lineup.catchers.some(s => s.id === e)) &&
            l.firsts.every(e => this.lineup.firstBasemen.some(s => s.id === e)) &&
            l.seconds.every(e => this.lineup.secondBasemen.some(s => s.id === e)) &&
            l.thirds.every(e => this.lineup.thirdBasemen.some(s => s.id === e)) &&
            l.sSs.every(e => this.lineup.shortstops.some(s => s.id === e)) &&
            l.oFs.every(e => this.lineup.outfielders.some(s => s.id === e));
        });
      },
      storedLineup() {
        return {
          Ps: this.lineup.pitchers.map(p => p.id),
          CsOr1B: this.lineup.catchersOr1B.map(c => c.id),
          Cs: this.lineup.catchers.map(c => c.id),
          Firsts: this.lineup.firstBasemen.map(f => f.id),
          Seconds: this.lineup.secondBasemen.map(s => s.id),
          Thirds: this.lineup.thirdBasemen.map(t => t.id),
          SSs: this.lineup.shortstops.map(s => s.id),
          OFs: this.lineup.outfielders.map(o => o.id),
          Captains: this.lineup.captainSpots.map(c => c.id),
          Stars: this.lineup.stars.map(s => s.id),
          Utils: this.lineup.utils.map(u => u.id),
          Mvps: this.lineup.mvpSpots.map(m => m.id),
        };
      },
      totalProjected: function () {
        return (
          this.lineup.captainSpots.reduce(
            (sum, p) => sum + p.customProjectedPoints.projectedPoints,
            0
          ) +
          this.lineup.mvpSpots.reduce(
            (sum, p) => sum + p.customProjectedPoints.projectedPoints,
            0
          ) +
          this.lineup.pitchers.reduce(
            (sum, p) => sum + p.customProjectedPoints.projectedPoints,
            0
          ) +
          this.lineup.catchersOr1B.reduce(
            (sum, p) => sum + p.customProjectedPoints.projectedPoints,
            0
          ) +
          this.lineup.catchers.reduce(
            (sum, p) => sum + p.customProjectedPoints.projectedPoints,
            0
          ) +
          this.lineup.firstBasemen.reduce(
            (sum, p) => sum + p.customProjectedPoints.projectedPoints,
            0
          ) +
          this.lineup.secondBasemen.reduce(
            (sum, p) => sum + p.customProjectedPoints.projectedPoints,
            0
          ) +
          this.lineup.thirdBasemen.reduce(
            (sum, p) => sum + p.customProjectedPoints.projectedPoints,
            0
          ) +
          this.lineup.shortstops.reduce(
            (sum, p) => sum + p.customProjectedPoints.projectedPoints,
            0
          ) +
          this.lineup.outfielders.reduce(
            (sum, p) => sum + p.customProjectedPoints.projectedPoints,
            0
          ) +
          this.lineup.utils.reduce(
            (sum, p) => sum + p.customProjectedPoints.projectedPoints,
            0
          ) +
          this.lineup.stars.reduce(
            (sum, p) => sum + p.customProjectedPoints.projectedPoints,
            0
          )
        );
      },
      totalActualScored: function () {
        return (
          this.lineup.captainSpots.reduce(
            (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
            0
          ) +
          this.lineup.mvpSpots.reduce(
            (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
            0
          ) +
          this.lineup.pitchers.reduce(
            (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
            0
          ) +
          this.lineup.catchersOr1B.reduce(
            (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
            0
          ) +
          this.lineup.catchers.reduce(
            (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
            0
          ) +
          this.lineup.firstBasemen.reduce(
            (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
            0
          ) +
          this.lineup.secondBasemen.reduce(
            (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
            0
          ) +
          this.lineup.thirdBasemen.reduce(
            (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
            0
          ) +
          this.lineup.shortstops.reduce(
            (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
            0
          ) +
          this.lineup.outfielders.reduce(
            (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
            0
          ) +
          this.lineup.utils.reduce(
            (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
            0
          ) +
          this.lineup.stars.reduce(
            (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
            0
          )
        );
      },
      totalSalary: function () {
        return (
          this.lineup.captainSpots.reduce((sum, p) => sum + p.salary.salary, 0) +
          this.lineup.mvpSpots.reduce((sum, p) => sum + p.salary.salary, 0) +
          this.lineup.pitchers.reduce((sum, p) => sum + p.salary.salary, 0) +
          this.lineup.catchersOr1B.reduce((sum, p) => sum + p.salary.salary, 0) +
          this.lineup.catchers.reduce((sum, p) => sum + p.salary.salary, 0) +
          this.lineup.firstBasemen.reduce((sum, p) => sum + p.salary.salary, 0) +
          this.lineup.secondBasemen.reduce((sum, p) => sum + p.salary.salary, 0) +
          this.lineup.thirdBasemen.reduce((sum, p) => sum + p.salary.salary, 0) +
          this.lineup.shortstops.reduce((sum, p) => sum + p.salary.salary, 0) +
          this.lineup.outfielders.reduce((sum, p) => sum + p.salary.salary, 0) +
          this.lineup.utils.reduce((sum, p) => sum + p.salary.salary, 0) + 
          this.lineup.stars.reduce((sum, p) => sum + p.salary.salary, 0)
        );
      },
      totalOwnership: function () {
        return (
          this.lineup.captainSpots.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0) +
          this.lineup.mvpSpots.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0) +
          this.lineup.pitchers.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0) +
          this.lineup.catchersOr1B.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0) +
          this.lineup.catchers.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0) +
          this.lineup.firstBasemen.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0) +
          this.lineup.secondBasemen.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0) +
          this.lineup.thirdBasemen.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0) +
          this.lineup.shortstops.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0) +
          this.lineup.outfielders.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0) +
          this.lineup.utils.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0) +
          this.lineup.stars.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0)
        );
      },
      ...mapState({
        slate: state => state.slates.selected,
        provider: state => state.providers.selected,
        settings: state => state.settings,
        storedLineups: state => state.storedLineups,
      })
    },
    methods: {
        getPlayerName(projection) {
          return projection.player.name;
        },
        saveLineup() {
          this.saveLineupStore({ providerId: this.provider.id, slateId: this.slate.id, lineup: this.storedLineup })
            .then(() => {
              this.$root.$emit('snackbar', {
                text: 'Lineup has been saved!',
                color: 'success'
              });
            })
            .catch(() => {
              this.$root.$emit('snackbar', {
                text: 'An error occurred while trying to save the lineup!',
                color: 'error'
              });
            });
        },
        removeSavedLineup() {
          this.removeSavedLineupStore({ providerId: this.provider.id, slateId: this.slate.id, lineup: this.storedLineup })
            .then(() => {
              this.$root.$emit('snackbar', {
                text: 'Saved lineup has been removed!',
                color: 'success'
              });
            })
            .catch(() => {
              this.$root.$emit('snackbar', {
                text: 'An error occurred while trying to remove the saved lineup!',
                color: 'error'
              });
            });
        },
        excludeLineup() {
          this.excludeLineupStore({ providerId: this.provider.id, slateId: this.slate.id, lineup: this.storedLineup })
            .then(() => {
              this.$root.$emit('snackbar', {
                text: 'Lineup has been excluded!',
                color: 'success'
              });
            })
            .catch(() => {
              this.$root.$emit('snackbar', {
                text: 'An error occurred while trying to exclude the lineup!',
                color: 'error'
              });
            });
        },
        removeExcludedLineup() {
          this.removeExcludedLineupStore({ providerId: this.provider.id, slateId: this.slate.id, lineup: this.storedLineup })
            .then(() => {
              this.$root.$emit('snackbar', {
                text: 'Excluded lineup has been removed!',
                color: 'success'
              });
            })
            .catch(() => {
              this.$root.$emit('snackbar', {
                text: 'An error occurred while trying to remove the excluded lineup!',
                color: 'error'
              });
            });
        },
        ...mapActions({
          saveLineupStore: 'saveLineup',
          removeSavedLineupStore: 'removeSavedLineup',
          excludeLineupStore: 'excludeLineup',
          removeExcludedLineupStore: 'removeExcludedLineup'
        })
    }
  };
  </script>
  <style scoped>
  .theme--dark.v-data-table {
    background-color: #0c2d48;
  }
  button.v-icon {
    margin: 0 3px;
  }
  </style>