<template>
    <div>
        <v-select
            v-model="slateValue"
            :disabled="disabled"
            :items="groupedSlates"
            no-data-text="No slates are currently available for this date"
            :item-text="displaySlate"
            item-value="id"
            label="Select Slate"
            :menu-props="{
                closeOnClick: true,
                closeOnContentClick: true,
            }"
            filled
            return-object
        >
            <!--
            <template v-slot:append-item v-if="provider?.abbreviation === 'FD'">
                <v-divider class="mb-2"></v-divider>
                <v-list-item @click="addSlateClick">
                    <v-list-item-content>
                        <v-list-item-title>
                            Add Slate
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            -->
        </v-select>
    </div>
</template>

<script>
export default {
    name: "SlateSelector",
    props: {
        selectedSlate: {
            required: true
        },
        slates: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
        };
    },
    computed: {
        slateValue: {
            get() {
                return this.selectedSlate;
            },
            set(newValue) {
                this.$gtag.event('change slate', { 
                    'event_category': 'Interaction',
                    'event_label': 'Slate Change',
                    'value': this.displaySlate(newValue)
                });
                this.$emit('change', newValue);
            }
        },
        groupedSlates() {
            var multi = this.slates.filter(s => s.numberOfGames > 1);
            var single = this.slates.filter(s => s.numberOfGames === 1);
            var items = [];
            if (multi.length > 0) {
                items.push({ header: 'Multi Game Slates' });
                items = items.concat(multi);
            }
            if (single.length > 0) {
                items.push({ header: 'Single Game Slates' });
                items = items.concat(single);
            }
            return items;
        }
    },
    methods: {
        displaySlate(slate) {
            const localDate = new Date(slate.start);
            const endDate = slate.end ? new Date(slate.end) : null;
            const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            var ret = localDate.toLocaleTimeString('en-US', { timeStyle: "short" });
            if (endDate && endDate.getDay() != localDate.getDay()) {
                ret = `${ret} ${dayNames[localDate.getDay()]}-${dayNames[endDate.getDay()]}`;
            } else {
                // Just use start day in slate display
                ret = `${ret} ${dayNames[localDate.getDay()]}`;
            }
            return `${ret} ${slate.typeDisplay}`;
        },
        addSlateClick() {
            console.log('Add a slate!');
        }
    }
};
</script>
<style scoped>
</style>