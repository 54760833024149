<template>
    <v-data-table
        dense
        :headers="headers"
        :items="viewStacks"
        :items-per-page="25"
        :footer-props="footerProps"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="elevation-1 top-margin">
        <template v-slot:[`item.key`]="{ item }">
            {{ item.key }}
        </template>
        <template v-slot:[`item.value`]="{ item }">
            {{ getExposurePercent(item.value) }}% ({{ item.value }})
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: "GameStackView",
    props: {
        lineups: {
            type: Array,
            required: true
        },
        games: {
            type: Array,
            required: true
        }
    },
    data: function () {
        return {
            sortBy: 'value',
            sortDesc: true,
            headers: [
                {
                    text: "Stack",
                    value: "key",
                    sortable: false,
                    divider: true
                },
                {
                    text: "Exposure",
                    value: "value",
                    divider: true,
                    width: '10%'
                }
            ],
        };
    },
    computed: {
        // Game stacks show many players one team has vs another team. So for example if a game is BOS @ DET and BOS has 3 players playing and DET has 4
        // the game stack would be displayed like:
        //  DET - 4 | BOS - 3
        // Game stacks work for both single and multi game slates
        viewStacks() {
            var stackResults = {};
            this.lineups.forEach(l => {
                this.games.forEach(g => {
                    var countTeam1 = l.allSpots.filter(p => p.team.abbreviation === g.homeTeam).length;
                    var countTeam2 = l.allSpots.filter(p => p.team.abbreviation === g.awayTeam).length;

                    if (countTeam1 > 0 && countTeam2 > 0) {
                        // Creating two records for each lineup
                        var keyTeam1 = `${g.homeTeam} - ${countTeam1}`;
                        var keyTeam2 = `${g.awayTeam} - ${countTeam2}`;
                        var key = '';

                        if (countTeam1 > countTeam2)
                            key = `${keyTeam1} | ${keyTeam2}`;
                        else
                            key = `${keyTeam2} | ${keyTeam1}`;

                        if (!stackResults[key])
                            stackResults[key] = 1;
                        else
                            stackResults[key]++;
                    }
                });
            });

            return Object.entries(stackResults).map(([key, value]) => {
                return {
                    key,
                    value
                };
            });
        },
        footerProps() {
            return {
                'items-per-page-options': [
                    10,
                    25,
                    50,
                    100,
                    -1
                ]
            };
        }
    },
    methods: {
        getExposurePercent(count) {
            return Math.round(((count / this.lineups.length) * 100).toFixed(1))
        }
    }
};
</script>
<style scoped>
</style>