<template>
    <v-col cols="12">
        <StackView v-for="stack in stacks" 
            :key="stack.id" 
            :stack="stack" 
            :projections="projections"
            v-on="$listeners" />
    </v-col>
</template>

<script>
import StackView from './StackView';

export default {
    name: "StacksList",
    components: {
        StackView
    },
    props: {
        stacks: {
            type: Array,
            required: true
        },
        projections: {
            type: Array,
            required: true
        },
    },
    data: function () {
        return {
        };
    }
};
</script>
<style scoped>
</style>