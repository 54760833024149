<template>
  <div>
    <v-simple-table dark>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Pos</th>
            <th class="text-left">Name</th>
            <th class="text-left">Team</th>
            <th class="text-left">Opp</th>
            <th class="text-left">Salary</th>
            <!--<th class="text-left">Own</th>-->
            <th class="text-left">Proj</th>
            <th class="text-left" v-if="backtesting">Pts</th>
          </tr>
        </thead>
        <tbody>
          <LineupViewRow v-for="plr in lineup.mvpSpots" :key="getPlayerName(plr)" :player="plr" position="MVP" :backtesting="backtesting" />
          <LineupViewRow v-for="plr in lineup.captainSpots" :key="getPlayerName(plr)" :player="plr" position="CPT" :backtesting="backtesting" />
          <LineupViewRow v-for="plr in lineup.quarterbacks" :key="getPlayerName(plr)" :player="plr" position="QB" :backtesting="backtesting" />
          <LineupViewRow v-for="plr in lineup.runningbacks" :key="getPlayerName(plr)" :player="plr" position="RB" :backtesting="backtesting" />
          <LineupViewRow v-for="plr in lineup.wideReceivers" :key="getPlayerName(plr)" :player="plr" position="WR" :backtesting="backtesting" />
          <LineupViewRow v-for="plr in lineup.tightEnds" :key="getPlayerName(plr)" :player="plr" position="TE" :backtesting="backtesting" />
          <LineupViewRow v-for="plr in lineup.flexSpots" :key="getPlayerName(plr)" :player="plr" position="FLEX" :backtesting="backtesting" />
          <LineupViewRow v-for="plr in lineup.defenses" :key="getPlayerName(plr)" :player="plr" position="DST" :backtesting="backtesting" />
        </tbody>
        <tfoot>
          <tr>
            <td colspan="2" v-if="!backtesting">
              <v-icon title="Saved Lineup" v-if="isSaved" color="green lighten-1">
                  mdi-table-check
              </v-icon>
              <v-icon @click="removeSavedLineup" title="Remove Saved Lineup" v-if="isSaved" color="red lighten-1">
                  mdi-close-circle
              </v-icon>
              <v-icon @click="removeExcludedLineup" title="Remove Excluded Lineup" v-if="isExcluded" color="red lighten-1">
                  mdi-close-circle
              </v-icon>
              <v-icon @click="saveLineup" title="Save Lineup" v-if="allowSaveRemoveLineup && !isSaved && !isExcluded">
                  mdi-table-plus
              </v-icon>
              <v-icon @click="excludeLineup" title="Exclude Lineup" v-if="allowSaveRemoveLineup && !isExcluded && !isSaved">
                  mdi-table-remove
              </v-icon>
            </td>
            <td colspan="2" v-else>
              Pt Diff: {{ (totalActualScored - totalProjected).toFixed(2) }}
            </td>
            <td colspan="2" class="text-right">Totals</td>
            <td>
              {{ totalSalary }}
            </td>
            <!--
            <td>
              {{ Math.round(totalOwnership) }}%
            </td>
            -->
            <td>
              {{ totalProjected.toFixed(2) }}
            </td>
            <td v-if="backtesting">
              {{ totalActualScored.toFixed(2) }}
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import LineupViewRow from './LineupViewRow.vue';

import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('football');

export default {
  name: "LineupView",
  components: {
    LineupViewRow
  },
  data: () => ({
  }),
  props: {
    lineup: {
      type: Object,
      required: true,
    },
    allowSaveRemoveLineup: {
      type: Boolean,
      default: true,
    },
    backtesting: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isSaved() {
      // Is this lineup already saved in settings?
      return this.storedLineups.savedLineups.some(l => {
        return l.captains.every(e => this.lineup.captainSpots.some(s => s.id === e)) &&
          l.defenses.every(e => this.lineup.defenses.some(s => s.id === e)) &&
          l.flex.every(e => this.lineup.flexSpots.some(s => s.id === e)) &&
          l.mvps.every(e => this.lineup.mvpSpots.some(s => s.id === e)) &&
          l.qbs.every(e => this.lineup.quarterbacks.some(s => s.id === e)) &&
          l.rbs.every(e => this.lineup.runningbacks.some(s => s.id === e)) &&
          l.tes.every(e => this.lineup.tightEnds.some(s => s.id === e)) &&
          l.wrs.every(e => this.lineup.wideReceivers.some(s => s.id === e));
      });
    },
    isExcluded() {
      // Is this lineup already excluded in settings?
      return this.storedLineups.excludedLineups.some(l => {
        return l.captains.every(e => this.lineup.captainSpots.some(s => s.id === e)) &&
          l.defenses.every(e => this.lineup.defenses.some(s => s.id === e)) &&
          l.flex.every(e => this.lineup.flexSpots.some(s => s.id === e)) &&
          l.mvps.every(e => this.lineup.mvpSpots.some(s => s.id === e)) &&
          l.qbs.every(e => this.lineup.quarterbacks.some(s => s.id === e)) &&
          l.rbs.every(e => this.lineup.runningbacks.some(s => s.id === e)) &&
          l.tes.every(e => this.lineup.tightEnds.some(s => s.id === e)) &&
          l.wrs.every(e => this.lineup.wideReceivers.some(s => s.id === e));
      });
    },
    storedLineup() {
      return {
        Qbs: this.lineup.quarterbacks.map(qb => qb.id),
        Rbs: this.lineup.runningbacks.map(rb => rb.id),
        Wrs: this.lineup.wideReceivers.map(wr => wr.id),
        Tes: this.lineup.tightEnds.map(te => te.id),
        Flex: this.lineup.flexSpots.map(f => f.id),
        Defenses: this.lineup.defenses.map(d => d.id),
        Captains: this.lineup.captainSpots.map(d => d.id),
        Mvps: this.lineup.mvpSpots.map(d => d.id)
      };
    },
    totalProjected: function () {
      return (
        this.lineup.captainSpots.reduce(
          (sum, p) => sum + p.customProjectedPoints.projectedPoints,
          0
        ) +
        this.lineup.mvpSpots.reduce(
          (sum, p) => sum + p.customProjectedPoints.projectedPoints,
          0
        ) +
        this.lineup.quarterbacks.reduce(
          (sum, p) => sum + p.customProjectedPoints.projectedPoints,
          0
        ) +
        this.lineup.runningbacks.reduce(
          (sum, p) => sum + p.customProjectedPoints.projectedPoints,
          0
        ) +
        this.lineup.wideReceivers.reduce(
          (sum, p) => sum + p.customProjectedPoints.projectedPoints,
          0
        ) +
        this.lineup.tightEnds.reduce(
          (sum, p) => sum + p.customProjectedPoints.projectedPoints,
          0
        ) +
        this.lineup.flexSpots.reduce(
          (sum, p) => sum + p.customProjectedPoints.projectedPoints,
          0
        ) +
        this.lineup.defenses.reduce(
          (sum, p) => sum + p.customProjectedPoints.projectedPoints,
          0
        )
      );
    },
    totalActualScored: function () {
      return (
        this.lineup.captainSpots.reduce(
          (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
          0
        ) +
        this.lineup.mvpSpots.reduce(
          (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
          0
        ) +
        this.lineup.quarterbacks.reduce(
          (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
          0
        ) +
        this.lineup.runningbacks.reduce(
          (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
          0
        ) +
        this.lineup.wideReceivers.reduce(
          (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
          0
        ) +
        this.lineup.tightEnds.reduce(
          (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
          0
        ) +
        this.lineup.flexSpots.reduce(
          (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
          0
        ) +
        this.lineup.defenses.reduce(
          (sum, p) => sum + p.actualFantasyPointsScored ?? 0,
          0
        )
      );
    },
    totalSalary: function () {
      return (
        this.lineup.captainSpots.reduce((sum, p) => sum + p.salary.salary, 0) +
        this.lineup.mvpSpots.reduce((sum, p) => sum + p.salary.salary, 0) +
        this.lineup.quarterbacks.reduce((sum, p) => sum + p.salary.salary, 0) +
        this.lineup.runningbacks.reduce((sum, p) => sum + p.salary.salary, 0) +
        this.lineup.wideReceivers.reduce((sum, p) => sum + p.salary.salary, 0) +
        this.lineup.tightEnds.reduce((sum, p) => sum + p.salary.salary, 0) +
        this.lineup.flexSpots.reduce((sum, p) => sum + p.salary.salary, 0) +
        this.lineup.defenses.reduce((sum, p) => sum + p.salary.salary, 0)
      );
    },
    totalOwnership: function () {
      return (
        this.lineup.captainSpots.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0) +
        this.lineup.mvpSpots.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0) +
        this.lineup.quarterbacks.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0) +
        this.lineup.runningbacks.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0) +
        this.lineup.wideReceivers.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0) +
        this.lineup.tightEnds.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0) +
        this.lineup.flexSpots.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0) +
        this.lineup.defenses.reduce((sum, p) => sum + p.projectedPoints.projectedOwnership, 0)
      );
    },
    ...mapState({
      slate: state => state.slates.selected,
      provider: state => state.providers.selected,
      settings: state => state.settings,
      storedLineups: state => state.storedLineups,
    })
  },
  methods: {
      getPlayerName(projection) {
        if (projection.player)
          return projection.player.name;
        return projection.team.mascot;
      },
      saveLineup() {
        this.saveLineupStore({ providerId: this.provider.id, slateId: this.slate.id, lineup: this.storedLineup })
          .then(() => {
            this.$root.$emit('snackbar', {
              text: 'Lineup has been saved!',
              color: 'success'
            });
          })
          .catch(() => {
            this.$root.$emit('snackbar', {
              text: 'An error occurred while trying to save the lineup!',
              color: 'error'
            });
          });
      },
      removeSavedLineup() {
        this.removeSavedLineupStore({ providerId: this.provider.id, slateId: this.slate.id, lineup: this.storedLineup })
          .then(() => {
            this.$root.$emit('snackbar', {
              text: 'Saved lineup has been removed!',
              color: 'success'
            });
          })
          .catch(() => {
            this.$root.$emit('snackbar', {
              text: 'An error occurred while trying to remove the saved lineup!',
              color: 'error'
            });
          });
      },
      excludeLineup() {
        this.excludeLineupStore({ providerId: this.provider.id, slateId: this.slate.id, lineup: this.storedLineup })
          .then(() => {
            this.$root.$emit('snackbar', {
              text: 'Lineup has been excluded!',
              color: 'success'
            });
          })
          .catch(() => {
            this.$root.$emit('snackbar', {
              text: 'An error occurred while trying to exclude the lineup!',
              color: 'error'
            });
          });
      },
      removeExcludedLineup() {
        this.removeExcludedLineupStore({ providerId: this.provider.id, slateId: this.slate.id, lineup: this.storedLineup })
          .then(() => {
            this.$root.$emit('snackbar', {
              text: 'Excluded lineup has been removed!',
              color: 'success'
            });
          })
          .catch(() => {
            this.$root.$emit('snackbar', {
              text: 'An error occurred while trying to remove the excluded lineup!',
              color: 'error'
            });
          });
      },
      ...mapActions({
        saveLineupStore: 'saveLineup',
        removeSavedLineupStore: 'removeSavedLineup',
        excludeLineupStore: 'excludeLineup',
        removeExcludedLineupStore: 'removeExcludedLineup'
      })
  }
};
</script>
<style scoped>
.theme--dark.v-data-table {
  background-color: #0c2d48;
}
button.v-icon {
  margin: 0 3px;
}
</style>