<template>
    <v-dialog
        v-model="fillContestsDialog"
        width="1000"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn small color="primary" v-bind="attrs" v-on="on" @click="autoSelectContests">
                Fill Contest Entries
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="text-h5 primary lighten-2">
                Fill Contest Entries
            </v-card-title>

            <v-card-text style="margin-top: 10px;">
                Select all the contests below you would like to fill from the lineups that have been built for you. Filling contesting entries will replace your current entries in those contests and give you a file that you can then
                upload directly to {{ provider.name }}.
            </v-card-text>

            <v-alert v-if="hasMoreBuildsThanEntries" type="info" style="width: 90%; margin: auto; margin-bottom: 20px;">
                You are building {{ buildingCount }} lineups but you only have {{ totalNumberOfEntries }} contest entries. Only the first {{ totalNumberOfEntries }} lineups will be used to fill your contests. 
                Adjust your build settings to match the number of entries in your contest to use all of the built lineups in your contest entries.
            </v-alert>

            <v-alert v-if="hasMoreEntriesThanBuilds" type="info" style="width: 90%; margin: auto; margin-bottom: 20px;">
                You are only building {{ buildingCount }} lineups but you have {{ totalNumberOfEntries }} contest entries. Not all of the entries in your contests will be filled. 
                Adjust your build settings to match the number of entries in your contest to completely fill all entries in your contests.
            </v-alert>

            <v-card-text>
                <MyContestsView :contests="contests"
                    :selected-contests="fillContestIds"
                    :view-options="contestViewOptions"
                    @selected-contest-change="selectedContestsChanged" />
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions style="clear: both;">
                <div style="float: left; width: 50%;">
                    <v-checkbox
                        style="display: inline-block; width: 45%;"
                        label="Download Entries File"
                        title="After the contests are filled, a file will be downloaded that you can upload to enter the lineups into your contests"
                        v-model="downloadFile"
                    ></v-checkbox>
                    <v-checkbox
                        style="display: inline-block; width: 40%;"
                        label="Open Upload Screen"
                        title="After the contests are filled, a new browser page will open where you can upload the file that was downloaded"
                        :disabled="!downloadFile"
                        v-model="openUploadPage"
                    ></v-checkbox>
                </div>
                <div style="float: right; width: 50%; text-align: right;">
                    <v-btn
                        :disabled="!hasSelectedContests"
                        style="margin-right: 10px;"
                        color="primary"
                        @click="fill"
                        :loading="fillingContests"
                    >
                        Fill Selected Contests
                    </v-btn>
                    <v-btn @click="cancel">
                        Cancel
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import MyContestsView from '@/components/shared/MyContestsView.vue';

export default {
  name: "FillContestsLineupModal",
  components: {
    MyContestsView
  },
  props: {
    provider: {
        required: true
    },
    contests: {
        type: Array,
        required: true,
    },
    buildingCount: {
        required: false
    }
  },
  data: function () {
    return {
        fillingContests: false,
        downloadFile: true,
        openUploadPage: true,
        fillContestsDialog: false,
        fillContestIds: [],
        contestViewOptions: {
            showCheckbox: true,
            showActions: false
        }
    };
  },
  computed: {
    hasSelectedContests() {
        return this.fillContestIds?.length > 0;
    },
    hasMoreBuildsThanEntries() {
        return this.buildingCount && this.buildingCount > this.totalNumberOfEntries;
    },
    hasMoreEntriesThanBuilds() {
        return this.buildingCount && this.totalNumberOfEntries > this.buildingCount;
    },
    totalNumberOfEntries() {
        return this.contests.reduce((a, b) => a + b.entries.length, 0);
    }
  },
  methods: {
    autoSelectContests() {
        this.fillContestIds = this.contests.map(c => c.contestId);
    },
    selectedContestsChanged(newValues) {
        this.fillContestIds = [...newValues];
    },
    fill() {
        this.fillingContests = true;
        this.$emit('fill-contests', 
            this.fillContestIds, 
            (response) => {     // Success callback
                if (response.status === 400 || response.status === 404) {
                    this.$root.$emit('snackbar', {
                        text: 'There was a problem with that request. Please try again!',
                        color: 'error'
                    });
                } else {
                    // Always reload contests when we were successful
                    this.$emit('reload-contests');

                    // if we are not downloading the file, snackbar success and close the modal
                    if (!this.downloadFile) {
                        this.$root.$emit('snackbar', {
                            text: 'Contest entries have been filled with lineups!',
                            color: 'success'
                        });
                        this.fillContestsDialog = false;
                    } else {
                        this.$emit('export-entries',
                            () => {     // Success callback
                                this.fillContestsDialog = false;
                                if (this.openUploadPage)
                                    window.open(this.getProviderUploadUrl(), '_blank').focus();
                            }
                        )
                    }
                }   
            },
            () => {             // Error callback
                this.$root.$emit('snackbar', {
                    text: 'An error occurred while trying to fill contest entries with lineups!',
                    color: 'error'
                });
            },
            () => {             // Completed callback
                this.fillingContests = false;
            });
    },
    getProviderUploadUrl() {
        if (this.provider.abbreviation === 'DK')
            return process.env.VUE_APP_DK_URL;
        if (this.provider.abbreviation === 'FD')
            return process.env.VUE_APP_FD_UPCOMING_URL; // TODO: This depends on whether the slate has started or not
    },
    cancel() {
        this.fillContestsDialog = false;
        this.fillContestIds = [];
    }
  }
};
</script>