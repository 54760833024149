<template>
    <v-data-table
        dense
        :headers="headers"
        :items="viewStacks"
        :items-per-page="25"
        :footer-props="footerProps"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="elevation-1 top-margin">
        <template v-slot:[`item.key`]="{ item }">
            {{ item.key }}
        </template>
        <template v-slot:[`item.value`]="{ item }">
            {{ getExposurePercent(item.value) }}% ({{ item.value }})
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: "PositionStackView",
    props: {
        lineups: {
            type: Array,
            require: true
        },
        teams: {
            type: Array,
            required: true
        }
    },
    data: function () {
        return {
            sortBy: 'value',
            sortDesc: true,
            headers: [
                {
                    text: "Stack",
                    value: "key",
                    sortable: false,
                    divider: true
                },
                {
                    text: "Exposure",
                    value: "value",
                    divider: true,
                    width: '10%'
                }
            ],
        };
    },
    computed: {
        viewStacks() {
            // This is just position stacks right now
            var stackResults = {};
            this.lineups.forEach(l => {
                var stackKeys = this.findStacksInLineup(l);
                stackKeys.forEach(key => {
                    if (!stackResults[key])
                        stackResults[key] = 1;
                    else
                        stackResults[key]++;
                });
            });

            return Object.entries(stackResults).map(([key, value]) => {
                return {
                    key,
                    value: value
                };
            });
        },
        footerProps() {
            return {
                'items-per-page-options': [
                    10,
                    25,
                    50,
                    100,
                    -1
                ]
            };
        }
    },
    methods: {
        // Find positional stacks in a given lineup. Return an array of keys for the stacks found
        findStacksInLineup(lineup) {
            const stackKeys = [];
            const qbs = lineup.allSpots.filter(p => p.playsPosition?.value == 'QB');
            qbs.forEach(qb => {
                const stackCount = lineup.allSpots.filter(p => (p.playsPosition?.value == 'WR' || p.playsPosition?.value == 'TE') && p.team.abbreviation === qb.team.abbreviation).length;
                if (stackCount >= 1)
                    stackKeys.push(`QB + ${stackCount}`);
            });
            return stackKeys;
        },
        getExposurePercent(count) {
            return Math.round(((count / this.lineups.length) * 100).toFixed(1))
        }
    }
};
</script>
<style scoped>
</style>