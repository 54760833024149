<template>
    <v-card class="odds-card">
        <div style="text-align: center;">
            <v-icon
                title="This game has started. All players playing in this game are no longer available to be added or removed from lineups."
                color="red"
                style="float: left; font-size: 20px;"
                v-if="odd.game.hasStarted"
            >
                mdi-lock
            </v-icon>
            {{ odd.overUnder }} Total
        </div>
        <div>
            <div class="team-block">
                <img :title="awayTeam.fullName" :src="getLogoUrl(awayTeam)" width="60" height="60" />
                <br />
                {{ odd.impliedAwayTotal }}
            </div>
            <div style="display: inline-block; text-align: center; width: 20px; vertical-align: top; padding-top: 20px;">
                <strong>@</strong>
            </div>
            <div class="team-block">
                <img :title="homeTeam.fullName" :src="getLogoUrl(homeTeam)" width="60" height="60" />
                <br />
                {{ odd.impliedHomeTotal }}
            </div>
        </div>
    </v-card>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('football');

export default {
  name: "GameOdds",
  props: {
    odd: {
        type: Object,
        required: true
    }
  },
  data: function () {
    return {
    };
  },
  computed: {
    homeTeam() {
        return this.getTeamByAbbreviation(this.odd.game.homeTeam);
    },
    awayTeam() {
        return this.getTeamByAbbreviation(this.odd.game.awayTeam);
    },
    ...mapGetters([
        'getTeamByAbbreviation'
    ])
  },
  methods: {
    getLogoUrl(team) {
        return require(`@/assets/team_logos/${team.logo}`);
    }
  }
};
</script>
<style scoped>
div.odds-card {
    margin: 0 5px;
    padding: 10px;
    width: 200px;
    display: inline-block;
}
div.team-block {
    display: inline-block;
    text-align: center;
    width: 80px;
}
</style>